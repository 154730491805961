import { Component, EventEmitter, Injector, Output, ViewChild, OnInit } from "@angular/core";
import { AppConsts } from "@shared/AppConsts";
import { AppComponentBase } from "@shared/common/app-component-base";
import {
    SettingScopes,
    StaffMemberProfileServiceProxy,
    UpdateGoogleAuthenticatorKeyOutput,
    SendVerificationSmsInputDto,
    ProfileServiceProxy,
    CurrentStaffMemberProfileEditViewModel,
    AddressViewModel
} from "@shared/service-proxies/service-proxies";
import { ModalDirective } from "ngx-bootstrap/modal";
import { SmsVerificationModalComponent } from "./sms-verification-modal.component";
import { finalize } from "rxjs/operators";
import { Subject } from "rxjs";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { AddressComponent, AddressEditForm } from "@app/shared/common/address/address.component";

@Component({
    selector: "staffMemberSettingsModal",
    templateUrl: "./staffmember-settings-modal.component.html"
})
export class StaffMemberSettingsModalComponent extends AppComponentBase implements OnInit {
    @ViewChild("staffMemberSettingsModal", { static: true }) modal: ModalDirective;
    @ViewChild("smsVerificationModal", { static: false }) smsVerificationModal: SmsVerificationModalComponent;

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    public staffMember: CurrentStaffMemberProfileEditViewModel;
    address: AddressViewModel = new AddressViewModel();
    staffMemberForm: FormGroup;
    sendSaveToChild: Subject<void> = new Subject<void>();

    public active = false;
    public saving = false;
    public isGoogleAuthenticatorEnabled = false;
    public isPhoneNumberConfirmed: boolean;
    public smsEnabled: boolean;
    public showTimezoneSelection: boolean = abp.clock.provider.supportsMultipleTimezone;
    public canChangeUserName: boolean;
    public defaultTimezoneScope: SettingScopes = SettingScopes.User;
    private _initialTimezone: string = undefined;
    public savedPhoneNumber: string;
    public newPhoneNumber: string;
    isMultiTenancyEnabled: boolean = this.multiTenancy.isEnabled;
    isTwoFactorLoginEnabledForApplication = false;

    constructor(
        injector: Injector,
        private _staffMemberProfileService: StaffMemberProfileServiceProxy,
        private _profileService: ProfileServiceProxy,
        private formBuilder: FormBuilder
    ) {
        super(injector);
        this.staffMemberForm = this.formBuilder.group({
            address: new FormGroup<AddressEditForm>({
                streetAddress: new FormControl(""),
                city: new FormControl(""),
                state: new FormControl(null),
                stateId: new FormControl(null),
                country: new FormControl({ value: null, disabled: true }),
                countryId: new FormControl(null),
                postalCode: new FormControl("")
            })
        });
    }

    ngOnInit(): void {
        this.isTwoFactorLoginEnabledForApplication = abp.setting.getBoolean(
            "Abp.Zero.UserManagement.TwoFactorLogin.IsEnabled"
        );
    }

    show(): void {
        this.active = true;
        this._staffMemberProfileService.getCurrentStaffMemberForEdit().subscribe((result) => {
            this.smsEnabled = this.setting.getBoolean("App.UserManagement.SmsVerificationEnabled");
            this.staffMember = result;
            this.address = result.address;
            this._initialTimezone = result.timezone;
            this.canChangeUserName = this.staffMember.userName !== AppConsts.userManagement.defaultAdminUserName;
            this.modal.show();
            this.isGoogleAuthenticatorEnabled = result.isGoogleAuthenticatorEnabled;
            this.isPhoneNumberConfirmed = result.isPhoneNumberConfirmed;
            this.savedPhoneNumber = result.phoneNumber;
            AddressComponent.mapAddressModelToFormGroup(
                this.address,
                this.staffMemberForm.get("address") as FormGroup<AddressEditForm>
            );
        });
    }

    updateQrCodeSetupImageUrl(): void {
        this._profileService.updateGoogleAuthenticatorKey().subscribe((result: UpdateGoogleAuthenticatorKeyOutput) => {
            this.staffMember.qrCodeSetupImageUrl = result.qrCodeSetupImageUrl;
            this.isGoogleAuthenticatorEnabled = true;
        });
    }

    disableGoogleAuthenticator(): void {
        this._profileService.disableGoogleAuthenticator().subscribe(() => {
            this.isGoogleAuthenticatorEnabled = false;
        });
    }

    smsVerify(): void {
        let input = new SendVerificationSmsInputDto();
        input.phoneNumber = this.staffMember.phoneNumber;
        this._profileService.sendVerificationSms(input).subscribe(() => {
            this.smsVerificationModal.show();
        });
    }

    changePhoneNumberToVerified(): void {
        this.isPhoneNumberConfirmed = true;
        this.savedPhoneNumber = this.staffMember.phoneNumber;
    }

    onShown(): void {
        document.getElementById("Name").focus();
    }

    close(): void {
        this.active = false;
        this.modal.hide();
    }

    save(): void {
        this.saving = true;
        this.staffMember.address = new AddressViewModel(this.staffMemberForm.value["address"]);
        this._staffMemberProfileService
            .updateCurrentStaffMember(this.staffMember)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe(() => {
                this.appSession.user.name = this.staffMember.name;
                this.appSession.user.surname = this.staffMember.surname;
                this.appSession.user.userName = this.staffMember.userName;
                this.appSession.user.emailAddress = this.staffMember.emailAddress;
                this.notify.info(this.l("SavedSuccessfully"));
                this.close();
                this.modalSave.emit(null);

                if (
                    abp.clock.provider.supportsMultipleTimezone &&
                    this._initialTimezone !== this.staffMember.timezone
                ) {
                    this.message.info(this.l("TimeZoneSettingChangedRefreshPageNotification")).then(() => {
                        window.location.reload();
                    });
                }
            });
    }
}
