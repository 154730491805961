import { Directive, ElementRef, HostListener, AfterViewInit, Input, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";

@Directive({
    selector: "[appCurrencyMask]",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CurrencyMaskDirective),
            multi: true
        }
    ]
})
export class CurrencyMaskDirective implements AfterViewInit, ControlValueAccessor {
    @Input("allowNegative") allowNegative = false;

    private inputElement: HTMLInputElement;
    private innerValue: number | undefined;
    private prefix = "$";
    private thousandsSeparator = ",";
    private decimalSeparator = ".";
    private decimalPrecision = 2;

    onChange: (_: unknown) => void = () => {};
    onTouched: () => void = () => {};

    constructor(elementRef: ElementRef) {
        this.inputElement = elementRef.nativeElement;
    }

    ngAfterViewInit(): void {
        this.inputElement.style.textAlign = "right";
    }

    writeValue(value: number | null): void {
        if (value === this.innerValue) {
            return;
        }

        const stringValue = value?.toString() ?? "";
        this.innerValue = this.parseNumber(stringValue);
        this.inputElement.value = this.transform(stringValue, true);
    }

    registerOnChange(onChange: (_: unknown) => void): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    @HostListener("focus", ["$event.target.value"])
    onfocus(value: string): void {
        this.inputElement.value = this.transform(value, false);
        this.inputElement.select();
    }

    @HostListener("keypress", ["$event"])
    keypress(event: KeyboardEvent): void {
        if (event.key === this.decimalSeparator) {
            const decimalIndex = this.inputElement.value.indexOf(this.decimalSeparator);
            if (decimalIndex < 0) {
                return;
            }

            const selectionStart = this.inputElement.selectionStart;
            const selectionEnd = this.inputElement.selectionEnd;

            this.inputElement.value = this.inputElement.value.replace(this.decimalSeparator, "");

            if (selectionStart === null || selectionEnd === null) {
                return;
            } else if (selectionStart === selectionEnd) {
                const newCursorIndex = decimalIndex >= selectionStart ? selectionStart : selectionStart - 1;
                this.inputElement.setSelectionRange(newCursorIndex, newCursorIndex);
            } else {
                this.inputElement.setSelectionRange(selectionStart, selectionEnd);
            }
        }
    }

    @HostListener("blur", ["$event.target.value"])
    blurred(value: string): void {
        this.onTouched();
        this.processValue(value);
    }

    @HostListener("change", ["$event.target.value"])
    changed(value: string): void {
        this.processValue(value);
    }

    private processValue(value: string): void {
        this.inputElement.value = this.transform(value, true);
        this.innerValue = this.parseNumber(value);
        this.onChange(this.innerValue);
    }

    private transform(value: string, withFormatting: boolean): string {
        let valueNumber = this.parseNumber(value);
        if (valueNumber === undefined) {
            return "";
        }

        const isNegative = valueNumber < 0;
        if (isNegative) {
            valueNumber *= -1;
        }

        const [integer, fraction = "0"] = valueNumber.toString().split(this.decimalSeparator);

        let formattedValue = `${
            withFormatting ? this.prefix + this.addThousandsSeparators(integer) : integer
        }.${this.fillTrailingZeros(fraction)}`;

        if (isNegative && withFormatting) {
            formattedValue = `(${formattedValue})`;
        } else if (isNegative) {
            formattedValue = "-" + formattedValue;
        }

        return formattedValue;
    }

    private addThousandsSeparators(integer: string): string {
        return integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandsSeparator);
    }

    private fillTrailingZeros(fraction: string): string {
        return (fraction + new Array(this.decimalPrecision).join("0")).substring(0, this.decimalPrecision);
    }

    private parseNumber(value: string): number | undefined {
        value = value?.trim() ?? "";

        const isNegative = this.allowNegative && (value.startsWith("(") || value.startsWith("-"));
        value = value.replace(/[^\d\.]/g, "");

        if (!value) {
            return;
        }

        let [integer, fraction = "0"] = value.split(this.decimalSeparator);
        if (fraction.length > this.decimalPrecision) {
            fraction = parseFloat(`0.${fraction}`).toFixed(this.decimalPrecision).substring(2);
        }

        return parseFloat(`${integer}.${fraction}`) * (isNegative ? -1 : 1);
    }
}
