<ng-container *ngIf="currentPage === voqServiceListPage">
    <div class="" role="actions">
        <button
            *ngIf="isGranted('Pages.Administration.VoqServices.Create')"
            (click)="createVoqService()"
            class="btn btn-primary blue"
        >
            <i class="fa fa-plus"></i>
            {{ l("CreateNewVoqService") }}
        </button>
    </div>
</ng-container>

<ng-container *ngIf="isVoqServiceEditPage">
    <div class="" role="actions">
        <a [routerLink]="voqServiceListPage">< {{ l("BackToServices") }}</a>
    </div>
</ng-container>

<ng-container *ngIf="isInvoicesEditPage">
    <div class="" role="actions">
        <a [routerLink]="invoiceListPage">< {{ l("BackToInvoices") }}</a>
    </div>
</ng-container>

<ng-container *ngIf="isStaffEditPage">
    <div class="" role="actions">
        <a [routerLink]="staffListPage">< {{ l("BackToTeam") }}</a>
    </div>
</ng-container>

<ng-container *ngIf="currentPage === invoiceListPage">
    <div class="" role="actions">
        <button
            *ngIf="isGranted('Pages.Administration.Invoices.Create')"
            class="btn btn-primary"
            (click)="createInvoice()"
        >
            <i class="fa fa-plus"></i>
            {{ l("CreateNewInvoice") }}
        </button>
    </div>
</ng-container>

<ng-container *ngIf="currentPage === clientListPage">
    <div class="" role="actions">
        <button
            *ngIf="isGranted('Pages.Administration.Clients.Create')"
            class="btn btn-primary"
            (click)="createClient()"
        >
            <i class="fa fa-plus"></i>
            {{ l("CreateNewClient") }}
        </button>
    </div>
</ng-container>

<ng-container *ngIf="isClientEditPage">
    <div class="" role="actions">
        <a [routerLink]="clientListPage">< {{ l("BackToClients") }}</a>
    </div>
</ng-container>

<ng-container *ngIf="isProjectEditPage">
    <div class="" role="actions">
        <a [routerLink]="projectListPage">< {{ l("BackToProjects") }}</a>
    </div>
</ng-container>

<ng-container *ngIf="currentPage === staffListPage">
    <div class="" role="actions">
        <button
            *ngIf="isGranted('Pages.Administration.StaffMembers.Create')"
            class="btn btn-primary"
            (click)="createStaffMember()"
        >
            <i class="fa fa-plus"></i>
            {{ l("InviteUser") }}
        </button>
    </div>
</ng-container>

<ng-container *ngIf="currentPage === projectListPage">
    <div class="" role="actions">
        <button
            *ngIf="isGranted('Pages.Administration.Projects.Create')"
            class="btn btn-primary"
            (click)="createProject()"
        >
            <i class="fa fa-plus"></i>
            {{ l("CreateNewProject") }}
        </button>
    </div>
</ng-container>

<ng-container *ngIf="currentPage === paymentsPage">
    <div class="" role="actions">
        <button
            *ngIf="isGranted('Pages.Administration.Payments.Create')"
            class="btn btn-primary"
            [routerLink]="addPaymentPage"
        >
            <i class="fa fa-plus"></i>
            {{ l("AddPayment") }}
        </button>
    </div>
</ng-container>

<ng-container *ngIf="isIssuesListPage">
    <div class="" role="actions">
        <button *ngIf="isGranted('Pages.Administration.Issues.Create')" class="btn btn-primary" (click)="createIssue()">
            <i class="fa fa-plus"></i>
            {{ l("CreateNewTask") }}
        </button>
    </div>
</ng-container>
