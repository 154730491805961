import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AutoFocusDirective } from "./auto-focus.directive";
import { BusyIfDirective } from "./busy-if.directive";
import { ButtonBusyDirective } from "./button-busy.directive";
import { FileDownloadService } from "./file-download.service";
import { FriendProfilePictureComponent } from "./friend-profile-picture.component";
import { LocalStorageService } from "./local-storage.service";
import { LuxonFormatPipe } from "./luxon-format.pipe";
import { LuxonFromNowPipe } from "./luxon-from-now.pipe";
import { ValidationMessagesComponent } from "./validation-messages.component";
import { EqualValidator } from "./validation/equal-validator.directive";
import { PasswordComplexityValidator } from "./validation/password-complexity-validator.directive";
import { NullDefaultValueDirective } from "./null-value.directive";
import { ScriptLoaderService } from "./script-loader.service";
import { StyleLoaderService } from "./style-loader.service";
import { ArrayToTreeConverterService } from "./array-to-tree-converter.service";
import { TreeDataHelperService } from "./tree-data-helper.service";
import { LocalizePipe } from "@shared/common/pipes/localize.pipe";
import { PermissionPipe } from "@shared/common/pipes/permission.pipe";
import { PermissionAnyPipe } from "@shared/common/pipes/permission-any.pipe";
import { PermissionAllPipe } from "@shared/common/pipes/permission-all.pipe";
import { FeatureCheckerPipe } from "@shared/common/pipes/feature-checker.pipe";
import { DatePickerLuxonModifierDirective } from "./date-time/date-picker-luxon-modifier.directive";
import { DateRangePickerLuxonModifierDirective } from "./date-time/date-range-picker-luxon-modifier.directive";
import { GuidGeneratorService } from "./guid-generator.service";
import { GreaterThanValidatorDirective } from "./validation/greater-than-validator.directive";
import { AbsoluteValuePipe } from "@shared/common/pipes/absoluteValue.pipe";
import { TimerDisplayFormatPipe } from "@shared/common/pipes/timerDisplayFormat.pipe";
import { SecondsOffsetFromDayPipe } from "@shared/common/pipes/secondsOffsetFromDay.pipe";
import { DateTimeDiffPipe } from "./date-time-diff.pipe";
import { CoerceDefaultDateTimeToNowPipe } from "./coerce-default-date-time-to-now.pipe";
import { EnumValueToKeyPipe } from "./enum-value-to-key.pipe";
import { SafeHtmlPipe } from "./safe-html.pipe";
import { RelativeDatePipe } from "@shared/common/pipes/relative-date.pipe";
import { AddressLineTwoPipe } from "@shared/common/pipes/address-line-two.pipe";
import { FlattenKeyValueListPipe } from "@shared/common/pipes/flattenKeyValueList.pipe";
import { FlattenListPipe } from "@shared/common/pipes/flattenList.pipe";
import { CompensationPipe } from "@shared/common/pipes/compensation.pipe";
import { ToTitleCasePipe } from "./to-title-case.pipe";
import { ToPascalCasePipe } from "./to-pascal-case.pipe";
import { FlattenKeyValueListComponent } from "./flatten-key-value-list.component";
import { IndicateIfRequiredPipe } from "@shared/common/pipes/indicate-if-required.pipe";
import { YesNoPipe } from "./yes-no.pipe";
import { HexToRgbaPipe } from "./hex-to-rgba.pipe";
import { NameInitialsPipe } from "@shared/common/pipes/nameInitials.pipe";
import { DiscountPipe } from "@shared/common/pipes/discount.pipe";

@NgModule({
    imports: [CommonModule],
    providers: [
        FileDownloadService,
        LocalStorageService,
        ScriptLoaderService,
        StyleLoaderService,
        ArrayToTreeConverterService,
        TreeDataHelperService,
        GuidGeneratorService
    ],
    declarations: [
        EqualValidator,
        PasswordComplexityValidator,
        GreaterThanValidatorDirective,
        ButtonBusyDirective,
        AutoFocusDirective,
        BusyIfDirective,
        FriendProfilePictureComponent,
        LuxonFormatPipe,
        LuxonFromNowPipe,
        ValidationMessagesComponent,
        NullDefaultValueDirective,
        LocalizePipe,
        PermissionPipe,
        PermissionAnyPipe,
        FeatureCheckerPipe,
        DatePickerLuxonModifierDirective,
        DateRangePickerLuxonModifierDirective,
        PermissionAllPipe,
        AbsoluteValuePipe,
        TimerDisplayFormatPipe,
        SecondsOffsetFromDayPipe,
        DateTimeDiffPipe,
        CoerceDefaultDateTimeToNowPipe,
        EnumValueToKeyPipe,
        SafeHtmlPipe,
        RelativeDatePipe,
        AddressLineTwoPipe,
        FlattenKeyValueListPipe,
        FlattenKeyValueListComponent,
        FlattenListPipe,
        CompensationPipe,
        ToTitleCasePipe,
        ToPascalCasePipe,
        IndicateIfRequiredPipe,
        YesNoPipe,
        HexToRgbaPipe,
        NameInitialsPipe,
        DiscountPipe
    ],
    exports: [
        EqualValidator,
        PasswordComplexityValidator,
        GreaterThanValidatorDirective,
        ButtonBusyDirective,
        AutoFocusDirective,
        BusyIfDirective,
        FriendProfilePictureComponent,
        LuxonFormatPipe,
        LuxonFromNowPipe,
        ValidationMessagesComponent,
        NullDefaultValueDirective,
        LocalizePipe,
        PermissionPipe,
        PermissionAnyPipe,
        FeatureCheckerPipe,
        DatePickerLuxonModifierDirective,
        DateRangePickerLuxonModifierDirective,
        PermissionAllPipe,
        AbsoluteValuePipe,
        TimerDisplayFormatPipe,
        SecondsOffsetFromDayPipe,
        DateTimeDiffPipe,
        CoerceDefaultDateTimeToNowPipe,
        EnumValueToKeyPipe,
        SafeHtmlPipe,
        RelativeDatePipe,
        AddressLineTwoPipe,
        FlattenKeyValueListPipe,
        FlattenKeyValueListComponent,
        FlattenListPipe,
        CompensationPipe,
        ToTitleCasePipe,
        ToPascalCasePipe,
        IndicateIfRequiredPipe,
        YesNoPipe,
        HexToRgbaPipe,
        NameInitialsPipe,
        DiscountPipe
    ]
})
export class UtilsModule { }
