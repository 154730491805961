import { Component, EventEmitter, Injector, Output, ViewChild } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ProjectSummaryModel } from "@shared/service-proxies/service-proxies";
import { ModalDirective } from "ngx-bootstrap/modal";

@Component({
    selector: "app-select-projects-modal",
    templateUrl: "./select-projects-modal.component.html"
})
export class SelectProjectsModalComponent extends AppComponentBase {
    @ViewChild("modal", { static: true }) modal!: ModalDirective;

    @Output() projectsSelected = new EventEmitter<ProjectSummaryModel[]>();

    projects: ProjectSummaryModel[] = [];
    selectedProjects: ProjectSummaryModel[] = [];

    constructor(injector: Injector) {
        super(injector);
    }

    async show(allProjects: ProjectSummaryModel[], selectedProjectsIds: number[]): Promise<void> {
        this.selectedProjects.length = 0;
        this.projects = allProjects.filter((i) => !selectedProjectsIds.includes(i.id));
        this.modal.show();
    }

    saveSelection(): void {
        this.projectsSelected.emit(this.selectedProjects);
        this.modal.hide();
    }
}
