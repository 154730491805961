import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "flattenList" })
export class FlattenListPipe implements PipeTransform {
    transform(items: string[]): string {
        if (items == null || items === undefined) {
            return "";
        }
        const totalCount = items.length;

        if (totalCount === 0) {
            return "-";
        }

        return items.map((item) => item).join(", ");
    }
}
