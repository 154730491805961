import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    standalone: true,
    selector: "app-play-pause-stop-button",
    templateUrl: "./play-pause-stop-button.component.html",
    styleUrls: ["./play-pause-stop-button.component.scss"],
    imports: [CommonModule]
})
export class PlayPauseStopButtonComponent {
    @Input() isRunning = false;
    @Input() enableHover = false;
    @Input() smallIcons = false;
    @Input() hoverDirection: "auto" | "up" | "down" = "down";
    @Input() useGrayScale = false;
    @Output() playClick = new EventEmitter<void>();
    @Output() stopClick = new EventEmitter<void>();
    @Output() cancelClick = new EventEmitter<void>();

    constructor() { }
}
