import { Injectable, Injector, NgZone } from "@angular/core";
import { HubConnection } from "@microsoft/signalr";
import { HubResult, HubResultWithPayload } from "@shared/models/HubResult";
import {
    TimeEntryBindingModel,
    TimeEntryModel,
    TimeLoggedForIssueModel
} from "@shared/service-proxies/service-proxies";
import { DateTime } from "luxon";
import { AppComponentBase } from "@shared/common/app-component-base";

export class TimerEventId {
    public static readonly Connected = "voq.timer.connected";
    public static readonly Disconnected = "voq.timer.disconnected";
    public static readonly Reconnected = "voq.timer.reconnected";
    public static readonly TimerStarted = "voq.timer.timerStarted";
    public static readonly TimerStopped = "voq.timer.timerStopped";
    public static readonly TimerCanceled = "voq.timer.timerCanceled";
    public static readonly TimeEntryUpdated = "voq.timer.timeEntryUpdated";
    public static readonly TimeEntryDeleted = "voq.timer.timeEntryDeleted";
    public static readonly TimeLoggedForIssueChanged = "voq.timer.timeLoggedForIssueChanged";
    public static readonly DescriptionUpdated = "voq.timer.descriptionUpdated";
    public static readonly IssueUpdated = "voq.timer.issueUpdated";
    public static readonly ProjectUpdated = "voq.timer.projectUpdated";
    public static readonly StartTimeAdjusted = "voq.timer.startTimeAdjusted";
    public static readonly StopTimeAdjusted = "voq.timer.stopTimeAdjusted";
    public static readonly VoqServiceUpdated = "voq.timer.voqServiceUpdated";
    public static readonly IsBillableUpdated = "voq.timer.isBillableUpdated";
}

@Injectable()
export class TimerSignalrService extends AppComponentBase {
    timerHub: HubConnection;
    isTimerConnected = false;

    constructor(
        injector: Injector,
        public _zone: NgZone
    ) {
        super(injector);
    }

    async init(): Promise<void> {
        await this._zone.runOutsideAngular(async () => {
            abp.signalr.connect();
            await abp.signalr.startConnection(abp.appPath + "signalr-timer", (connection: HubConnection) => {
                this.configureConnection(connection);
            });
            this.isTimerConnected = true;
            try {
                const currentTimeEntry = await this.getCurrentTimeEntry();
                abp.event.trigger(TimerEventId.Connected, currentTimeEntry);
            } catch (error) {
                abp.log.error(`Failed to connect the timer, Reason:\n${error}`);
                abp.notify.error(error);
            }
        });
    }

    configureConnection(connection: HubConnection): void {
        // Set the common hub
        this.timerHub = connection;

        let reconnectTime = 5000;
        let tries = 1;
        let maxTries = 8;
        function start(): Promise<void> {
            return new Promise(function (resolve, reject) {
                if (tries > maxTries) {
                    // TODO: Display modal to prompt reload once we have given up attempting to reconnect
                    reject();
                } else {
                    connection
                        .start()
                        .then(resolve)
                        .then(() => {
                            reconnectTime = 5000;
                            tries = 1;
                        })
                        .catch(() => {
                            setTimeout(() => {
                                start().then(resolve);
                            }, reconnectTime);
                            reconnectTime *= 2;
                            tries += 1;
                        });
                }
            });
        }

        // Reconnect if hub disconnects
        connection.onclose(async (e) => {
            this.isTimerConnected = false;
            abp.event.trigger(TimerEventId.Disconnected);

            if (e) {
                abp.log.debug("Timer connection closed with error: " + e);
            } else {
                abp.log.debug("Timer disconnected");
            }

            try {
                await start();
                this.isTimerConnected = true;
            } catch (error) {
                abp.log.error(`Failed to reconnect the timer, Reason:\n${error}`);
                abp.notify.error(error);
            }

            try {
                const currentTimeEntry = await this.getCurrentTimeEntry();
                abp.event.trigger(TimerEventId.Reconnected, currentTimeEntry);
            } catch (error) {
                abp.log.error(`Failed getCurrentTimeEntry, Reason:\n${error}`);
                // HACK: This is a bug with AspNetZero's hub implementation. If the access token expires, the context is lost and userId is always null.
                // Forced reload is the only known fix ATM. We could potentially just reinitialize the hub connection or the timer page, but reloading the browser is easier and more reliable.
                abp.notify.error("Session expired, reloading...");
                location.reload();
            }
        });

        // Register to get notifications
        this.registerTimerEvents(connection);
    }

    registerTimerEvents(connection: HubConnection): void {
        connection.on("TimerStarted", (timeEntry) => {
            abp.event.trigger(TimerEventId.TimerStarted, TimeEntryModel.fromJS(timeEntry));
        });
        connection.on("TimerStopped", (timeEntry) => {
            abp.event.trigger(TimerEventId.TimerStopped, TimeEntryModel.fromJS(timeEntry));
        });
        connection.on("TimerCanceled", () => {
            abp.event.trigger(TimerEventId.TimerCanceled);
        });
        connection.on("DescriptionUpdated", (description: string) => {
            abp.event.trigger(TimerEventId.DescriptionUpdated, description);
        });
        connection.on("IssueUpdated", (issueId: number | undefined) => {
            abp.event.trigger(TimerEventId.IssueUpdated, issueId);
        });
        connection.on("ProjectUpdated", (projectId: number | undefined) => {
            abp.event.trigger(TimerEventId.ProjectUpdated, projectId);
        });
        connection.on("StartTimeAdjusted", (startTime: string) => {
            abp.event.trigger(TimerEventId.StartTimeAdjusted, DateTime.fromISO(startTime));
        });
        connection.on("StopTimeAdjusted", (stopTime: string) => {
            abp.event.trigger(TimerEventId.StopTimeAdjusted, DateTime.fromISO(stopTime));
        });
        connection.on("VoqServiceUpdated", (voqServiceId: number | undefined) => {
            abp.event.trigger(TimerEventId.VoqServiceUpdated, voqServiceId);
        });
        connection.on("IsBillableUpdated", (isBillable: boolean) => {
            abp.event.trigger(TimerEventId.IsBillableUpdated, isBillable);
        });
        connection.on("TimeEntryUpdated", (timeEntry) => {
            abp.event.trigger(TimerEventId.TimeEntryUpdated, TimeEntryModel.fromJS(timeEntry));
        });
        connection.on("TimeEntryDeleted", (timeEntryId: number) => {
            abp.event.trigger(TimerEventId.TimeEntryDeleted, timeEntryId);
        });
        connection.on("TimeLoggedForIssueChanged", (timelogged: TimeLoggedForIssueModel) => {
            abp.event.trigger(TimerEventId.TimeLoggedForIssueChanged, TimeLoggedForIssueModel.fromJS(timelogged));
        });
    }

    async getCurrentTimeEntry(): Promise<TimeEntryModel | undefined> {
        const timeEntry = await this.invokeHubMethodWithResult<TimeEntryModel>("GetCurrentTimeEntry");
        return timeEntry ? TimeEntryModel.fromJS(timeEntry) : undefined;
    }

    async startTimer(timeEntry: TimeEntryBindingModel): Promise<StartTimerResult> {
        const result = await this.invokeHubMethodWithResult<StartTimerResult>(
            "StartTimer",
            new StartTimerCommand(timeEntry)
        );
        return {
            oldTimeEntry: result.oldTimeEntry ? TimeEntryModel.fromJS(result.oldTimeEntry) : undefined,
            newTimeEntry: TimeEntryModel.fromJS(result.newTimeEntry)
        };
    }

    async stopTimer(): Promise<TimeEntryModel> {
        const viewModel = await this.invokeHubMethodWithResult<TimeEntryModel>("StopTimer", new StopTimerCommand());
        return TimeEntryModel.fromJS(viewModel);
    }

    async cancelTimer(): Promise<void> {
        await this.invokeHubMethod("CancelTimer");
    }

    async adjustStartTime(updatedTime: DateTime, forceOverwriteIds?: Array<number>): Promise<TimeEntryModel> {
        const viewModel = await this.invokeHubMethodWithResult<TimeEntryModel>(
            "AdjustStartTime",
            updatedTime,
            forceOverwriteIds ?? null
        );
        return TimeEntryModel.fromJS(viewModel);
    }

    async updateDescription(description: string): Promise<void> {
        await this.invokeHubMethod("UpdateDescription", description);
    }

    async updateIssue(issueId: number | undefined): Promise<void> {
        await this.invokeHubMethod("UpdateIssue", issueId);
    }

    async updateProject(projectId: number | undefined): Promise<void> {
        await this.invokeHubMethod("UpdateProject", projectId);
    }

    async updateVoqService(voqServiceId: number | undefined): Promise<void> {
        await this.invokeHubMethod("UpdateVoqService", voqServiceId);
    }

    async updateIsBillable(isBillable: boolean): Promise<void> {
        await this.invokeHubMethod("UpdateIsBillable", isBillable);
    }

    async createTimeEntry(timeEntry: TimeEntryBindingModel): Promise<TimeEntryModel> {
        const viewModel = await this.invokeHubMethodWithResult<TimeEntryModel>("CreateTimeEntry", timeEntry);
        return TimeEntryModel.fromJS(viewModel);
    }

    async updateTimeEntry(timeEntry: TimeEntryBindingModel): Promise<TimeEntryModel> {
        const viewModel = await this.invokeHubMethodWithResult<TimeEntryModel>("UpdateTimeEntry", timeEntry);
        return TimeEntryModel.fromJS(viewModel);
    }

    private async invokeHubMethod(methodName: string, ...args: any[]): Promise<void> {
        if (!this.isTimerConnected) {
            throw new Error("Timer is not connected");
        }

        let result: HubResult;
        result = await this.timerHub.invoke<HubResult>(methodName, ...args);

        if (result.isFailed) {
            throw Error(result.errorMessage);
        }
    }

    private async invokeHubMethodWithResult<TResult>(methodName: string, ...args: any[]): Promise<TResult> {
        if (!this.isTimerConnected) {
            throw new Error("Timer is not connected");
        }

        let result: HubResultWithPayload<TResult>;
        result = await this.timerHub.invoke<HubResultWithPayload<TResult>>(methodName, ...args);

        if (result.isFailed) {
            throw result.payload ? Error(result.errorMessage, { cause: result.payload }) : Error(result.errorMessage);
        }
        return result.payload;
    }
}

export class TimerInput {
    public minutesOffset: number;

    constructor() {
        // TODO: Confirm this luxon offset is equivalent to moment().utcOffset()
        this.minutesOffset = DateTime.local().offset;
    }
}

export class StartTimerCommand extends TimerInput {
    public startTime: DateTime;
    public projectId: number;
    public issueId: number;
    public voqServiceId: number;
    public description: string;
    public isBillable: boolean;

    constructor(timeEntry: TimeEntryBindingModel) {
        super();
        this.startTime = timeEntry.startTime;
        this.projectId = timeEntry.projectId;
        this.issueId = timeEntry.issueId;
        this.voqServiceId = timeEntry.voqServiceId;
        this.description = timeEntry.description;
        this.isBillable = timeEntry.isBillable;
    }
}

export interface StartTimerResult {
    oldTimeEntry: TimeEntryModel | undefined;
    newTimeEntry: TimeEntryModel;
}

export class StopTimerCommand extends TimerInput {
    constructor() {
        super();
    }
}

export class UpdateTimerCommand {
    timeEntryId: number;
    projectId: number;
    issueId: number;
    voqServiceId: number;
    description: string;

    constructor(timeEntry: TimeEntryBindingModel) {
        this.timeEntryId = timeEntry.id;
        this.projectId = timeEntry.projectId;
        this.issueId = timeEntry.issueId;
        this.voqServiceId = timeEntry.voqServiceId;
        this.description = timeEntry.description;
    }
}
