<div
    class="wrapper date-time-control"
    [formGroup]="datePickerForm"
    [class.form-floating]="displayLabel"
    [class.is-invalid]="displayLabel && displayError"
    [class.show-calendar]="displayDate"
>
    <input
        #timeInput
        formControlName="time"
        type="time"
        [step]="displaySeconds ? 1 : 60"
        name="time"
        [ngClass]="inputClass"
        placeholder="--:--"
        (focus)="onTouched(); inputFocusChange.emit()"
        (blur)="inputBlur.emit()"
        (keyup.enter)="timeInput.blur()"
        (keyup.esc)="inputKeyupEscape.emit(); timeInput.blur()"
    />
    <label [for]="timeControl" *ngIf="displayLabel">{{ displayLabel }}</label>
    <p-calendar
        *ngIf="displayDate"
        formControlName="date"
        appendTo="body"
        [showIcon]="displayIcon"
        hideOnDateTimeSelect="false"
        dateFormat="mm/dd"
        (onShow)="onTouched(); inputFocusChange.emit()"
        (onClose)="inputBlur.emit()"
    ></p-calendar>
</div>
