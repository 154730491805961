import { Injectable } from "@angular/core";
import { TokenService, RefreshTokenService } from "abp-ng2-module";
import { Observable, Subject, of } from "rxjs";
import { AppConsts } from "@shared/AppConsts";
import { LocalStorageService } from "@shared/utils/local-storage.service";
import { RefreshTokenResult, TokenAuthServiceProxy } from "@shared/service-proxies/service-proxies";
import { jwtDecode } from "jwt-decode";

@Injectable({
    providedIn: "root"
})
export class ZeroRefreshTokenService implements RefreshTokenService {
    constructor(
        private _tokenAuthService: TokenAuthServiceProxy,
        private _tokenService: TokenService,
        private _localStorageService: LocalStorageService
    ) {}

    tryAuthWithRefreshToken(): Observable<boolean> {
        let refreshTokenObservable = new Subject<boolean>();

        let token = this._tokenService.getRefreshToken();
        if (!token?.trim()) {
            return of(false);
        }

        this._tokenAuthService.refreshToken(token).subscribe(
            (tokenResult: RefreshTokenResult) => {
                if (tokenResult && tokenResult.accessToken) {
                    let tokenExpireDate = new Date(new Date().getTime() + 1000 * tokenResult.expireInSeconds);
                    this._tokenService.setToken(tokenResult.accessToken, tokenExpireDate);
                    this._localStorageService.setItem(AppConsts.authorization.encrptedAuthTokenName, {
                        token: tokenResult.encryptedAccessToken,
                        expireDate: tokenExpireDate
                    });

                    refreshTokenObservable.next(true);
                    refreshTokenObservable.complete();
                } else {
                    refreshTokenObservable.next(false);
                    refreshTokenObservable.complete();
                }
            },
            () => {
                refreshTokenObservable.next(false);
                refreshTokenObservable.complete();
            }
        );
        return refreshTokenObservable;
    }

    async localStorageHasToken(): Promise<boolean> {
        return new Promise((resolve, reject) =>
            this._localStorageService.getItem(AppConsts.authorization.encrptedAuthTokenName, (err, value) => {
                resolve(!!value?.token);
            })
        );
    }

    tokenIsExpired(): boolean {
        const token = this._tokenService.getToken();
        if (!token) {
            return false;
        }

        const decodedToken = jwtDecode(token);
        const expirationTime = decodedToken.exp;
        const currentTime = Math.floor(Date.now() / 1000);

        return currentTime >= expirationTime;
    }
}
