import { Injectable } from "@angular/core";
import {
    IssueServiceProxy,
    JiraIntegrationServiceProxy,
    IssueSummaryModel
} from "@shared/service-proxies/service-proxies";
import { BehaviorSubject, Observable, of } from "rxjs";
import { concatMap, first, map, shareReplay, tap } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class IssuesService {
    private issues = new BehaviorSubject<IssueSummaryModel[]>([]);
    readonly issues$ = this.issues.asObservable();

    jiraIntegrationUrl: Observable<string>;

    constructor(
        private issueServiceProxy: IssueServiceProxy,
        private jiraIntegrationServiceProxy: JiraIntegrationServiceProxy
    ) {
        this.jiraIntegrationUrl = this.jiraIntegrationServiceProxy.getJiraIntegrationUrl().pipe(shareReplay());
    }

    clearCachedIssues(): void {
        this.issues.next([]);
    }

    getCachedIssueById(id?: number): Observable<IssueSummaryModel | null> {
        return this.issues.pipe(
            concatMap((issues) => {
                if (!id) {
                    return of(null);
                }
                const issue = issues?.find((i) => i.id === id);
                if (issue) {
                    return of(issue);
                }
                return this.issueServiceProxy
                    .getAssignedSummaryById(id)
                    .pipe(tap((issue) => this.issues.next([issue])));
            }),
            first()
        );
    }

    getIssueByIssueKey(issueKey: string): Observable<IssueSummaryModel> {
        return this.issueServiceProxy.getAssignedSummaryList(undefined, issueKey, undefined, 1).pipe(
            tap((results) => this.issues.next([...results])),
            map((results) => results[0])
        );
    }

    getIssuesByProject(projectId: number): Observable<IssueSummaryModel[]> {
        return this.issueServiceProxy
            .getAssignedSummaryList(undefined, undefined, projectId, 10)
            .pipe(tap((results) => this.issues.next(results)));
    }

    findIssues(filter: string): Observable<IssueSummaryModel[]> {
        return this.issueServiceProxy
            .getAssignedSummaryList(filter, undefined, undefined, 10)
            .pipe(tap((results) => this.issues.next(results)));
    }
}
