<div class="row" [formGroup]="formGroup">
    <div class="col-12 mb-5" [ngClass]="{ 'col-md-6': layout === 'twoColumn' }">
        <label for="StreetAddress" class="mb-1">
            {{ "StreetAddress" | localize }}{{ "streetAddress" | indicateIfRequired: formGroup }}
        </label>
        <input type="text" id="StreetAddress" class="form-control" formControlName="streetAddress" />
    </div>

    <div class="col-12 mb-5" [ngClass]="{ 'col-md-6': layout === 'twoColumn' }">
        <label for="City" class="mb-1">{{ "City" | localize }}{{ "city" | indicateIfRequired: formGroup }}</label>
        <input type="text" id="City" class="form-control" formControlName="city" />
    </div>

    <div class="col-12 mb-5" [ngClass]="{ 'col-md-6': layout === 'twoColumn' }">
        <label for="State" class="mb-1">{{ "State" | localize }}{{ "state" | indicateIfRequired: formGroup }}</label>
        <p-dropdown
            id="State"
            name="State"
            class="ml-2"
            dataKey="id"
            formControlName="state"
            [options]="states"
            optionLabel="name"
            showClear="true"
            filter="true"
            filterBy="name"
            (onChange)="onStateSelection($event)"
        ></p-dropdown>
    </div>

    <div class="col-12 mb-5" [ngClass]="{ 'col-md-6': layout === 'twoColumn' }">
        <label for="Country" class="mb-1">
            {{ "Country" | localize }}{{ "country" | indicateIfRequired: formGroup }}
        </label>
        <p-dropdown
            id="Country"
            name="Country"
            class="ml-2"
            formControlName="country"
            [options]="countries"
            placeholder="{{ l('UnitedStates') }}"
            (onChange)="onCountrySelection($event)"
        ></p-dropdown>
    </div>

    <div class="col-12 mb-5" [ngClass]="{ 'col-md-6': layout === 'twoColumn' }">
        <label for="PostalCode" class="mb-1">
            {{ "PostalCode" | localize }}{{ "postalCode" | indicateIfRequired: formGroup }}
        </label>
        <input type="text" id="PostalCode" class="form-control" formControlName="postalCode" />
    </div>
</div>
