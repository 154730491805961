import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "toTitleCase"
})
export class ToTitleCasePipe implements PipeTransform {
    transform(value: string): string {
        return value?.replace(/([a-z\d])([A-Z])/g, "$1 $2")?.replace(/^\w/, (str) => str.toUpperCase());
    }
}
