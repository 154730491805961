import { Duration } from "luxon";
import parse from "parse-duration";

export type DurationFormat = "colon" | "colonShort" | "component" | "fractional" | "fractionShort";

export class DurationUtils {
    public static fromSeconds(
        seconds: number | undefined,
        format: DurationFormat | string = "colonShort"
    ): string | null {
        if (seconds === undefined) {
            return null;
        }

        const input = Duration.fromObject({ seconds });

        if (format === "colonShort") {
            return input.toFormat("hh:mm");
        } else if (format === "colon") {
            return input.toFormat("hh:mm:ss");
        } else if (format === "component") {
            return this.getComponentFormat(input);
        } else if (format === "fractional") {
            const fractionalHours = Math.round((seconds / 3600) * 100) / 100;
            return `${fractionalHours}h`;
        } else if (format === "fractionShort") {
            const fractionalHours = Math.round((seconds / 3600) * 100) / 100;
            return `${fractionalHours}`;
        } else {
            //TODO: Verify idempotency with moment
            return input.toFormat(format);
        }
    }

    public static parseDuration(input: string): Duration {
        let parsedDurationAsMilliseconds: number;
        if (DurationUtils.shouldUseSimplifiedParser(input)) {
            parsedDurationAsMilliseconds = DurationUtils.simpleParse(input);
        } else {
            const parsedInput = parse(input);
            if (parsedInput === undefined) {
                throw new Error(`${input} is not a valid duration.`);
            }
            parsedDurationAsMilliseconds = parsedInput;
        }
        const newDuration = Duration.fromMillis(parsedDurationAsMilliseconds);
        if ((input.length && parsedDurationAsMilliseconds === null) || !newDuration.isValid) {
            throw new Error(`${input} is not a valid duration.`);
        }
        return newDuration;
    }

    // TODO: Send to parsing utility class
    private static shouldUseSimplifiedParser(input: string): boolean {
        const regex = RegExp(/^(\d{1,2}:)?\d{1,2}(?::\d{1,2})?$/);
        return regex.test(input);
    }

    // TODO: Send to parsing utility class
    private static simpleParse(input: string): number {
        let total = 0;
        const components = input.split(":");
        if (components.length === 1) {
            total = parseInt(components[0]) * 60000;
        } else if (components.length === 2) {
            total = parseInt(components[0]) * 3600000 + parseInt(components[1]) * 60000;
        } else if (components.length === 3) {
            total =
                parseInt(components[0]) * 3600000 + parseInt(components[1]) * 60000 + parseInt(components[2]) * 1000;
        }

        return total;
    }

    private static getComponentFormat(input: Duration): string {
        let format = "m'm' s's'";
        if (input.as("days") > 1) {
            format = "d'd' h'h' " + format;
        } else if (input.as("hours") > 1) {
            format = "h'h' " + format;
        }

        return input.toFormat(format);
    }
}
