import { Injectable } from "@angular/core";
import { EntityStatus, ProjectsServiceProxy, ProjectSummaryModel } from "@shared/service-proxies/service-proxies";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class ProjectsService {
    projects$: Observable<ProjectSummaryModel[]>;

    constructor(private projectsServiceProxy: ProjectsServiceProxy) {
        //TODO: This caches the first request for projects and never rehydrates for its lifetime
        this.projects$ = this.projectsServiceProxy.getAssignedSummaryList().pipe(shareReplay(1));
    }

    getCachedProjectById(id?: number): Observable<ProjectSummaryModel | undefined> {
        return this.projects$.pipe(map((projects) => projects.find((p) => p.id === id)));
    }

    getProjectsByIssuePrefix(issuePrefix: string): Observable<ProjectSummaryModel[]> {
        return this.projects$.pipe(
            map((projects) => projects.filter((p) => p.issuePrefix?.toLowerCase() === issuePrefix.toLowerCase()))
        );
    }

    getActivePlusSelected(id: number): Observable<ProjectSummaryModel[]> {
        return this.projects$.pipe(
            map((projects) => projects.filter((p) => p.status === EntityStatus.Active || p.id === id))
        );
    }
}
