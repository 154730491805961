import { Injectable, ErrorHandler } from "@angular/core";
import { ApplicationinsightsAngularpluginErrorService } from "@microsoft/applicationinsights-angularplugin-js";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private aiErrorService: ApplicationinsightsAngularpluginErrorService) {}

    handleError(error: string | Error | unknown): void {
        console.error(error);
        this.aiErrorService.handleError(error);
    }
}
