import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "nameInitials" })
export class NameInitialsPipe implements PipeTransform {
    transform(value: string): string {
        if (!value.trim()) {
            return "N/A";
        }

        const splits = value.trim().split(" ");
        if (splits.length === 1) {
            return value.substring(0, 2);
        } else {
            return splits[0].substring(0, 1) + splits[splits.length - 1].substring(0, 1);
        }
    }
}
