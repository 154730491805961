import { FeatureCheckerService, PermissionCheckerService } from "abp-ng2-module";
import { AppSessionService } from "@shared/common/session/app-session.service";

import { Injectable } from "@angular/core";
import { AppMenu } from "./app-menu";
import { AppMenuItem } from "./app-menu-item";

@Injectable()
export class AppNavigationService {
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService,
        private _featureCheckerService: FeatureCheckerService
    ) {}

    getMenu(): AppMenu {
        const items = [
            new AppMenuItem(
                "Dashboard",
                "Pages.Administration.Host.Dashboard",
                "dashboard-icon",
                "/app/admin/hostDashboard"
            ),
            new AppMenuItem("Dashboard", "Pages.Tenant.Dashboard", "dashboard-icon", "/app/main/dashboard"),
            new AppMenuItem("Tenants", "Pages.Tenants", "undefined-icon", "/app/admin/tenants"),
            new AppMenuItem("Editions", "Pages.Editions", "undefined-icon", "/app/admin/editions"),
            new AppMenuItem("Clients", "Pages.Administration.Clients", "client-icon", "/app/admin/clients"),
            new AppMenuItem("Projects", "Pages.Administration.Projects", "projects-icon", "/app/admin/projects"),
            new AppMenuItem("Tasks", "Pages.Administration.Issues", "issues-icon", "/app/main/tasks"),
            new AppMenuItem("Invoices", "Pages.Administration.Invoices", "invoices-icon", "/app/main/invoices"),
            new AppMenuItem("Payments", "Pages.Administration.Payments", "payments-icon", "/app/main/payments"),
            new AppMenuItem("Team", "Pages.Administration.StaffMembers", "staff-icon", "/app/admin/staff-members"),
            new AppMenuItem("VoqServices", "Pages.Administration.VoqServices", "services-icon", "/app/admin/services"),
            new AppMenuItem("TimeEntries", "Pages.TimeEntries", "time-entries-icon", "/app/main/time-entries"),
            new AppMenuItem(
                "ExternalVendors",
                "Pages.Administration.ExternalVendors",
                "vendors-icon",
                "/app/admin/external-vendors"
            ),
            new AppMenuItem("Reports", "Pages.Administration.Reports", "reports-icon", "/app/main/reporting/reports"),
            new AppMenuItem(
                "Administration",
                "Pages.Administration",
                "admin-icon",
                "",
                [],
                [
                    new AppMenuItem(
                        "Settings",
                        "Pages.Administration.Tenant.Settings",
                        "settings-icon",
                        "/app/admin/tenant-settings"
                    ),
                    new AppMenuItem(
                        "OrganizationUnits",
                        "Pages.Administration.OrganizationUnits",
                        "undefined-icon",
                        "/app/admin/organization-units"
                    ),
                    new AppMenuItem(
                        "Integrations",
                        "Pages.Administration.Integrations",
                        "integrations-icon",
                        "/app/admin/integrations"
                    ),
                    new AppMenuItem(
                        "Subscription",
                        "Pages.Administration.Tenant.SubscriptionManagement",
                        "subscription-icon",
                        "/app/admin/subscription-management"
                    ),
                    new AppMenuItem("Roles", "Pages.Administration.Roles", "undefined-icon", "/app/admin/roles"),
                    new AppMenuItem("Users", "Pages.Administration.Users", "staff-icon", "/app/admin/users"),
                    new AppMenuItem(
                        "Languages",
                        "Pages.Administration.Languages",
                        "undefined-icon",
                        "/app/admin/languages",
                        ["/app/admin/languages/{name}/texts"]
                    ),
                    new AppMenuItem(
                        "AuditLogs",
                        "Pages.Administration.AuditLogs",
                        "undefined-icon",
                        "/app/admin/auditLogs"
                    ),
                    new AppMenuItem(
                        "Maintenance",
                        "Pages.Administration.Host.Maintenance",
                        "undefined-icon",
                        "/app/admin/maintenance"
                    ),
                    new AppMenuItem(
                        "VisualSettings",
                        "Pages.Administration.UiCustomization",
                        "undefined-icon",
                        "/app/admin/ui-customization"
                    ),
                    new AppMenuItem(
                        "WebhookSubscriptions",
                        "Pages.Administration.WebhookSubscription",
                        "undefined-icon",
                        "/app/admin/webhook-subscriptions"
                    ),
                    new AppMenuItem(
                        "DynamicProperties",
                        "Pages.Administration.DynamicProperties",
                        "undefined-icon",
                        "/app/admin/dynamic-property"
                    ),
                    new AppMenuItem(
                        "Host Settings",
                        "Pages.Administration.Host.Settings",
                        "settings-icon",
                        "/app/admin/hostSettings"
                    ),
                    new AppMenuItem(
                        "Notifications",
                        "Pages.Administration.MassNotifications",
                        "notification-icon",
                        "",
                        [],
                        [
                            new AppMenuItem(
                                "Inbox",
                                "Pages.Administration.MassNotifications",
                                "notification-icon",
                                "/app/notifications"
                            ),
                            new AppMenuItem(
                                "MassNotifications",
                                "Pages.Administration.MassNotifications",
                                "notification-icon",
                                "/app/admin/mass-notifications"
                            )
                        ],
                        undefined,
                        undefined,
                        () => {
                            return this._featureCheckerService.isEnabled("App.FutureFeatures");
                        }
                    )
                ]
            ),
            new AppMenuItem(
                "DemoUiComponents",
                "Pages.DemoUiComponents",
                "undefined-icon",
                "/app/admin/demo-ui-components"
            )
        ];
        return new AppMenu(
            "MainMenu",
            "MainMenu",
            items
                .filter((menuItem) => this.showMenuItem(menuItem))
                .map((menuItem) => {
                    if (menuItem.items) {
                        menuItem.items = menuItem.items.filter((subItem) => this.showMenuItem(subItem));
                    }
                    // If there is only one child item and it's a group, then replace main item with the only child
                    return menuItem.items?.length === 1 ? menuItem.items[0] : menuItem;
                })
        );
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === "" || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }
        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === "Pages.Administration.Tenant.SubscriptionManagement" &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        if (!menuItem.route && !menuItem.items?.length) {
            hideMenuItem = true;
        }

        if ((menuItem.name === "TimeEntries" || menuItem.name === "Timer") && !this._appSessionService.tenantId) {
            hideMenuItem = true;
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach((menuItem) => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach((subMenu) => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
