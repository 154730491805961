import { AppConsts } from "@shared/AppConsts";
import { LocalStorageService } from "@shared/utils/local-storage.service";

export class SignalRHelper {
    static initSignalR(callback: () => void): void {
        new LocalStorageService().getItem(
            AppConsts.authorization.encrptedAuthTokenName,
            function (err: any, value: any) {
                let encryptedAuthToken = value?.token;

                abp.signalr = {
                    autoConnect: false, // _zone.runOutsideAngular in ChatSignalrService
                    // autoReconnect: true,
                    connect: undefined as any,
                    qs: encryptedAuthToken
                        ? AppConsts.authorization.encrptedAuthTokenName + "=" + encodeURIComponent(encryptedAuthToken)
                        : "",
                    remoteServiceBaseUrl: AppConsts.remoteServiceBaseUrl,
                    startConnection: undefined as any,
                    url: "/signalr",
                    withUrlOptions: {},
                    hubs: undefined as any
                };

                let script = document.createElement("script");
                script.onload = () => {
                    callback();
                };

                script.src = AppConsts.appBaseUrl + "/assets/abp/abp.signalr-client.js";
                document.head.appendChild(script);
            }
        );
    }
}
