import { CommonModule } from "@angular/common";
import { Component, Injector, Input, NgZone, OnDestroy, OnInit, signal } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { TimerEventId } from "@app/shared/layout/timer/timer-signalr.service";
import { AppComponentBase } from "@shared/common/app-component-base";
import { DurationUtils } from "@shared/helpers/duration-utils";
import {
    IssueSummaryModel,
    ProjectSummaryModel,
    VoqServiceSummaryModel
} from "@shared/service-proxies/service-proxies";
import { UtilsModule } from "@shared/utils/utils.module";
import { TimeEntryDescriptionComponent } from "@timer/components/time-entry-description/time-entry-description.component";
import { DescriptionParseResult, DescriptionService } from "@timer/services/description.service";
import { IssuesService } from "@timer/services/issues.service";
import { VoqServicesService } from "@timer/services/voq-services.service";
import { DateTime, Duration } from "luxon";
import { BehaviorSubject, combineLatest, firstValueFrom, Subject } from "rxjs";
import { combineLatestWith, concatMap, distinctUntilChanged, filter, takeUntil } from "rxjs/operators";
import { ConnectivityService } from "../../../timer/services/connectivity.service";
import { ProjectsService } from "../../../timer/services/projects.service";
import { TimerService } from "../../../timer/services/timer.service";
import { PlayPauseStopButtonComponent } from "../play-pause-stop-button/play-pause-stop-button.component";
import { TaskProgressComponent } from "../task-progress/task-progress.component";
import { TimeEntryListItemForm } from "../time-entry-list-item/time-entry-list-item.component";
import { TimeEntryRangePickerComponent, TimeRange } from "../time-entry-range-picker/time-entry-range-picker.component";
import { TimerFieldDetailsComponent } from "../timer-field-details/timer-field-details.component";

@Component({
    standalone: true,
    selector: "app-timer-interface",
    templateUrl: "./timer-interface.component.html",
    styleUrls: ["./timer-interface.component.scss"],
    styles: `
        .duration {
            font-size: 20px;
            font-weight: 500;
            width: 90px;
        }
    `,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        PlayPauseStopButtonComponent,
        TimerFieldDetailsComponent,
        TimeEntryDescriptionComponent,
        TimeEntryRangePickerComponent,
        TaskProgressComponent,
        UtilsModule
    ]
})
export class TimerInterfaceComponent extends AppComponentBase implements OnInit, OnDestroy {
    @Input() layout: "horizontal" | "vertical" = "vertical";

    parentForm: FormGroup<TimeEntryListItemForm>;
    defaultDate: DateTime = DateTime.now().startOf("day");
    private readonly parsing$ = new BehaviorSubject<boolean>(false);
    readonly durationIsFocused$ = new BehaviorSubject<boolean>(false);
    private readonly startRequested$ = new BehaviorSubject<boolean>(false);
    private readonly unsubscribeSubject$ = new Subject<void>();

    editMode = signal(false);
    startingDuration = signal(0);

    get descriptionControl(): FormControl<string> {
        return this.parentForm.get("description") as FormControl<string>;
    }
    get projectControl(): FormControl<ProjectSummaryModel> {
        return this.parentForm.get("project") as FormControl<ProjectSummaryModel>;
    }
    get voqServiceControl(): FormControl<VoqServiceSummaryModel> {
        return this.parentForm.get("voqService") as FormControl<VoqServiceSummaryModel>;
    }
    get billableControl(): FormControl<boolean> {
        return this.parentForm.get("billable") as FormControl<boolean>;
    }
    get issueControl(): FormControl<IssueSummaryModel> {
        return this.parentForm.get("issue") as FormControl<IssueSummaryModel>;
    }
    get assigneeControl(): FormControl<{ name: string; code: string }> {
        return this.parentForm.get("assignee") as FormControl<{ name: string; code: string }>;
    }
    get statusControl(): FormControl<{ name: string; code: string }> {
        return this.parentForm.get("status") as FormControl<{ name: string; code: string }>;
    }
    get timeRangeControl(): FormControl<TimeRange> {
        return this.parentForm.controls.timeRange;
    }
    constructor(
        public formBuilder: FormBuilder,
        public connectivityService: ConnectivityService,
        public timerService: TimerService,
        public projectsService: ProjectsService,
        public voqServicesService: VoqServicesService,
        public issuesService: IssuesService,
        public zone: NgZone,
        private descriptionService: DescriptionService,
        injector: Injector
    ) {
        super(injector);

        this.parentForm = formBuilder.group<TimeEntryListItemForm>({
            description: new FormControl(null),
            project: new FormControl(null),
            voqService: new FormControl({ value: null, disabled: true }),
            billable: new FormControl(true),
            issue: new FormControl(null),
            assignee: new FormControl({ value: null, disabled: true }),
            status: new FormControl({ value: null, disabled: true }),
            timeRange: new FormControl(null)
        });
    }

    ngOnInit(): void {
        this.subscribeToTimerEvents();
        this.subscribeToInputEvents();
    }

    override ngOnDestroy(): void {
        this.unsubscribeSubject$.next(void 0);
        this.unsubscribeSubject$.complete();
        super.ngOnDestroy();
    }

    private subscribeToTimerEvents(): void {
        this.timerService.projectId$
            .pipe(
                takeUntil(this.unsubscribeSubject$),
                filter((projectId) => projectId !== this.projectControl.value?.id),
                concatMap((projectId) => this.projectsService.getCachedProjectById(projectId))
            )
            .subscribe({
                next: (project) => {
                    this.parentForm.patchValue({ project }, { emitEvent: false });
                    if (project) {
                        this.voqServiceControl.enable({ emitEvent: false });
                    } else {
                        this.voqServiceControl.disable({ emitEvent: false });
                    }
                }
            });
        this.timerService.issueId$
            .pipe(
                takeUntil(this.unsubscribeSubject$),
                filter((issueId) => issueId !== this.issueControl.value?.id),
                concatMap((issueId) => this.issuesService.getCachedIssueById(issueId))
            )
            .subscribe({ next: (issue) => this.parentForm.patchValue({ issue }, { emitEvent: false }) });
        this.timerService.voqServiceId$
            .pipe(
                takeUntil(this.unsubscribeSubject$),
                filter((voqServiceId) => voqServiceId !== this.voqServiceControl.value?.id),
                concatMap((voqServiceId) => this.voqServicesService.getVoqServiceById(voqServiceId))
            )
            .subscribe({ next: (voqService) => this.parentForm.patchValue({ voqService }, { emitEvent: false }) });
        this.timerService.description$
            .pipe(
                takeUntil(this.unsubscribeSubject$),
                distinctUntilChanged(),
                combineLatestWith(this.timerService.isRunning$)
            )
            .subscribe({
                next: ([description, isRunning]) =>
                    this.parentForm.patchValue({ description }, { emitEvent: !isRunning })
            });
        this.timerService.isBillable$
            .pipe(takeUntil(this.unsubscribeSubject$), distinctUntilChanged())
            .subscribe({ next: (billable: boolean) => this.parentForm.patchValue({ billable }, { emitEvent: false }) });

        this.timerService.startTime$
            .pipe(
                takeUntil(this.unsubscribeSubject$),
                distinctUntilChanged(),
                filter((s) => s !== undefined))
            .subscribe({
                next: (startTime: DateTime) =>
                    this.parentForm.patchValue({ timeRange: { startTime: startTime.toJSDate(), stopTime: new Date() } }, { emitEvent: false })
            });

        combineLatest([this.timerService.secondsElapsed$, this.durationIsFocused$, this.timerService.isRunning$])
            .pipe(
                takeUntil(this.unsubscribeSubject$),
                filter(([, durationIsFocused, isRunning]) => !durationIsFocused && !isRunning)
            )
            .subscribe({
                next: ([secondsElapsed]) => {
                    this.startingDuration.update(() => secondsElapsed);
                }
            });

        this.subscribeToEvent(TimerEventId.DescriptionUpdated, (description: string) => {
            this.zone.run(() => {
                this.parentForm.patchValue({ description }, { emitEvent: false });
            });
        });
    }

    private subscribeToInputEvents(): void {
        this.descriptionControl.valueChanges.pipe(takeUntil(this.unsubscribeSubject$)).subscribe({
            next: async (description) => {
                try {
                    await this.timerService.updateDescription(description);
                } catch (error) {
                    abp.notify.error(error);
                }
            }
        });
        this.timeRangeControl.valueChanges.pipe(takeUntil(this.unsubscribeSubject$)).subscribe({
            next: async (timeRange) => {
                try {
                    await this.timerService.adjustStartTime(timeRange.startTime);
                } catch (error) {
                    abp.notify.error(error);
                }
            }
        });
        this.projectControl.valueChanges.pipe(takeUntil(this.unsubscribeSubject$)).subscribe({
            next: async (project) => {
                try {
                    await this.timerService.updateProject(project?.id);
                    if (project && !this.voqServiceControl.value) {
                        //Note: Shouldn't we be using a cached value to get the voq service? The only reason we are doing this is because we blow away the voq service.
                        const parseResult = await firstValueFrom(
                            this.descriptionService.parseDescription(this.descriptionControl.value)
                        );
                        if (!!parseResult.voqServiceId) {
                            const voqService = await firstValueFrom(
                                this.voqServicesService.getVoqServiceById(parseResult.voqServiceId)
                            );
                            if (voqService) {
                                this.voqServiceControl.setValue(voqService);
                            }
                        }
                    }
                } catch (error) {
                    abp.notify.error(error);
                }
            }
        });
        this.voqServiceControl.valueChanges.pipe(takeUntil(this.unsubscribeSubject$)).subscribe({
            next: async (voqService) => {
                try {
                    await this.timerService.updateVoqService(voqService?.id);
                } catch (error) {
                    abp.notify.error(error);
                }
            }
        });
        this.issueControl.valueChanges.pipe(takeUntil(this.unsubscribeSubject$)).subscribe({
            next: async (issue) => {
                try {
                    await this.timerService.updateIssue(issue?.id);
                } catch (error) {
                    abp.notify.error(error);
                }
            }
        });
        this.billableControl.valueChanges.pipe(takeUntil(this.unsubscribeSubject$)).subscribe({
            next: async (billable) => {
                try {
                    await this.timerService.updateIsBillable(billable);
                } catch (error) {
                    abp.notify.error(error);
                }
            }
        });

        combineLatest([this.startRequested$, this.parsing$])
            .pipe(
                takeUntil(this.unsubscribeSubject$),
                filter(([startRequested, parsing]) => startRequested && !parsing)
            )
            .subscribe({
                next: async () => {
                    try {
                        await this.startTimer();
                    } catch (error) {
                        abp.notify.error(error);
                    }
                }
            });
    }

    async requestTimerStart(): Promise<void> {
        const isWaiting = await firstValueFrom(this.timerService.isWaiting$);
        const isRunning = await firstValueFrom(this.timerService.isRunning$);
        if (isWaiting || isRunning) {
            return;
        }

        this.startRequested$.next(true);
    }

    private async startTimer(): Promise<void> {
        this.startRequested$.next(false);
        await this.timerService.startTimer();
    }

    onDescriptionParseStart(): void {
        this.parsing$.next(true);
    }

    async onDescriptionParsed(event: DescriptionParseResult | null): Promise<void> {
        try {
            if (event?.issueId && event.issueId !== this.issueControl.value?.id) {
                await this.timerService.updateIssue(event.issueId);
            } else if (event?.projectId && event.projectId !== this.projectControl.value?.id) {
                const project = await firstValueFrom(this.projectsService.getCachedProjectById(event.projectId));
                if (project.issuePrefix !== this.projectControl.value?.issuePrefix) {
                    await this.timerService.updateProject(event.projectId);
                }
            }

            if (
                event?.voqServiceId &&
                event.voqServiceId !== this.voqServiceControl.value?.id &&
                this.voqServiceControl.enabled &&
                this.projectControl.value?.voqServiceIds.includes(event.voqServiceId)
            ) {
                await this.timerService.updateVoqService(event.voqServiceId);
            }
        } catch (error) {
            abp.notify.error(error);
        } finally {
            this.parsing$.next(false);
        }
    }

    async stopTimer(): Promise<void> {
        this.projectControl.setValidators([Validators.required]);
        this.projectControl.updateValueAndValidity({ emitEvent: false });
        this.voqServiceControl.setValidators([Validators.required]);
        this.voqServiceControl.updateValueAndValidity({ emitEvent: false });
        this.timeRangeControl.setValidators([Validators.required]);
        this.timeRangeControl.updateValueAndValidity({ emitEvent: false });

        if (this.parentForm.invalid) {
            this.projectControl.markAsDirty();
            this.voqServiceControl.markAsDirty();
            this.timeRangeControl.markAsDirty();
            return;
        }

        await this.timerService.stopTimer();
        this.clearValidators();
    }

    async cancelTimer(): Promise<void> {
        await this.timerService.cancelTimer();
        this.clearValidators();
    }

    clearValidators(): void {
        this.projectControl.clearValidators();
        this.projectControl.updateValueAndValidity({ emitEvent: false });
        this.voqServiceControl.clearValidators();
        this.voqServiceControl.updateValueAndValidity({ emitEvent: false });
        this.descriptionControl.updateValueAndValidity({ emitEvent: false });
        this.timeRangeControl.clearValidators();
        this.timeRangeControl.updateValueAndValidity({ emitEvent: false });
        this.parentForm.markAsPristine();
    }

    async updateDuration(input: string): Promise<void> {
        const lastDuration = Duration.fromObject({ seconds: Math.floor(this.startingDuration()) });
        this.durationIsFocused$.next(false);

        const trimmedInput = input.trim();
        if (!trimmedInput.length) {
            return;
        }

        const newDuration = DurationUtils.parseDuration(trimmedInput);
        if (lastDuration.minus(newDuration).as("seconds") === 0) {
            return;
        }

        try {
            await this.timerService.adjustStartTime(DateTime.local().minus(newDuration).toJSDate());
        } catch (error) {
            abp.notify.error(error);
        }
    }

    toggleEditMode(): void {
        this.editMode.update(() => !this.editMode());
    }
}
