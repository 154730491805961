import { Injectable } from "@angular/core";
import { Observable, of, zip } from "rxjs";
import { concatMap, first, map } from "rxjs/operators";
import { IssuesService } from "./issues.service";
import { ProjectsService } from "./projects.service";
import { VoqServicesService } from "./voq-services.service";

@Injectable({
    providedIn: "root"
})
export class DescriptionService {
    constructor(
        private projectsService: ProjectsService,
        private issuesService: IssuesService,
        private voqServicesService: VoqServicesService
    ) {}

    parseDescription(description: string): Observable<DescriptionParseResult> {
        const groups = description?.match(/^(\w+)(-\d+)?/);

        let issuePrefix: string, issueKey: string | undefined;
        if (groups) {
            issuePrefix = groups[1];
            if (groups[2]) {
                issueKey = issuePrefix + groups[2];
            }
        }

        const $issue = issueKey ? this.issuesService.getIssueByIssueKey(issueKey) : of(null);
        const $projectAndIssueIds = $issue.pipe(
            concatMap((issue) => {
                if (issue) {
                    return of({
                        issueId: issue.id,
                        projectId: issue.projectId
                    });
                } else if (issuePrefix) {
                    return this.projectsService.getProjectsByIssuePrefix(issuePrefix).pipe(
                        map((ps) => {
                            const project = ps.find((p) => p.isDefaultProject) ?? ps[0];
                            return {
                                issueId: null,
                                projectId: project?.id ?? null
                            };
                        })
                    );
                } else {
                    return of({ issueId: null, projectId: null });
                }
            })
        );
        const $voqServiceId = this.voqServicesService.voqServices$.pipe(
            map(
                (vs) =>
                    vs.find((s) =>
                        [s.name?.toLowerCase(), ...(s.aliases?.map((a) => a.toLowerCase()) ?? [])].some(
                            (s2) => description?.toLowerCase().includes(s2 ?? "") ?? false
                        )
                    )?.id ?? null
            )
        );

        return zip($projectAndIssueIds, $voqServiceId).pipe(
            map(
                ([projectAndIssueIds, voqServiceId]) =>
                    ({
                        ...projectAndIssueIds,
                        voqServiceId: voqServiceId
                    }) as DescriptionParseResult
            ),
            first()
        );
    }
}

export interface DescriptionParseResult {
    projectId?: number;
    issueId?: number;
    voqServiceId?: number;
}
