import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { AppComponentBase } from "@shared/common/app-component-base";
import { Subject, delay, distinctUntilChanged, map, of, switchMap, takeUntil } from "rxjs";

interface FilterForm {
    filterText: FormControl<string | null>;
}

@Component({
    templateUrl: "./text-filter.component.html",
    selector: "app-text-filter"
})
export class TextFilterComponent extends AppComponentBase implements OnInit, OnChanges, OnDestroy {
    @Input() filter = "";
    @Output() filterChange = new EventEmitter<string>();

    filterForm: FormGroup<FilterForm>;

    get filterControl(): FormControl<string | null> {
        return this.filterForm.controls.filterText;
    }

    private readonly cancelDebounceSubject$ = new Subject<void>();
    private readonly emitValues$ = new Subject<string | null>();
    private readonly unsubscribeSubject$ = new Subject<void>();

    constructor(
        injector: Injector,
        public formBuilder: FormBuilder
    ) {
        super(injector);

        this.filterForm = this.formBuilder.group<FilterForm>({
            filterText: new FormControl<string>(this.filter)
        });
    }

    ngOnInit(): void {
        this.filterControl.valueChanges
            .pipe(
                takeUntil(this.unsubscribeSubject$),
                switchMap((value) => {
                    this.cancelDebounceSubject$.next();
                    return of(value).pipe(delay(500), takeUntil(this.cancelDebounceSubject$));
                })
            )
            .subscribe((value: string) => this.emitValues$.next(value));

        this.emitValues$
            .pipe(
                takeUntil(this.unsubscribeSubject$),
                map((value) => value?.trim()),
                distinctUntilChanged()
            )
            .subscribe((value) => this.filterChange.emit(value));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.filter) {
            this.filterControl.setValue(changes.filter.currentValue?.trim(), { emitEvent: false });
        }
    }

    override ngOnDestroy(): void {
        this.cancelDebounceSubject$.next(void 0);
        this.cancelDebounceSubject$.complete();
        this.unsubscribeSubject$.next(void 0);
        this.unsubscribeSubject$.complete();
        super.ngOnDestroy();
    }

    onSubmit(): void {
        this.cancelDebounceSubject$.next();
        this.emitValues$.next(this.filterControl.value);
    }
}
