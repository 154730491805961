import { Injectable, NgZone, OnDestroy } from "@angular/core";
import {
    IssueServiceProxy,
    TimeEntryBindingModel,
    TimeEntryModel,
    TimeLoggedForIssueModel
} from "@shared/service-proxies/service-proxies";
import { TimerEventId, TimerSignalrService } from "@app/shared/layout/timer/timer-signalr.service";
import { DateTime } from "luxon";
import {
    BehaviorSubject,
    combineLatest,
    firstValueFrom,
    from,
    interval,
    Observable,
    of,
    Subject,
    Subscription,
    zip
} from "rxjs";
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    first,
    map,
    switchMap,
    takeUntil,
    tap,
    withLatestFrom
} from "rxjs/operators";
import { ProjectsService } from "./projects.service";

@Injectable({
    providedIn: "root"
})
export class TimerService implements OnDestroy {
    private readonly UPDATE_INTERVAL = 1000;

    protected readonly timeEntryId = new BehaviorSubject<number | undefined>(undefined);
    readonly timeEntryId$ = this.timeEntryId.asObservable();
    protected readonly timerId = new BehaviorSubject<number | undefined>(undefined);
    readonly timerId$ = this.timerId.asObservable();
    protected readonly description = new BehaviorSubject<string | undefined>(undefined);
    readonly description$ = this.description.asObservable();
    protected readonly issueId = new BehaviorSubject<number | undefined>(undefined);
    readonly issueId$ = this.issueId.asObservable();
    protected readonly projectId = new BehaviorSubject<number | undefined>(undefined);
    readonly projectId$ = this.projectId.asObservable();
    protected readonly voqServiceId = new BehaviorSubject<number | undefined>(undefined);
    readonly voqServiceId$ = this.voqServiceId.asObservable();
    protected readonly isBillable = new BehaviorSubject<boolean | undefined>(undefined);
    readonly isBillable$ = this.isBillable.asObservable();
    protected readonly startTime = new BehaviorSubject<DateTime | undefined>(undefined);
    readonly startTime$ = this.startTime.asObservable();
    protected readonly stopTime = new BehaviorSubject<DateTime | undefined>(undefined);
    readonly stopTime$ = this.stopTime.asObservable();

    protected readonly isRunning = new BehaviorSubject<boolean>(false);
    readonly isRunning$ = this.isRunning.asObservable();
    protected readonly isWaiting = new BehaviorSubject<boolean>(false);
    readonly isWaiting$ = this.isWaiting.asObservable();

    protected readonly secondsElapsed = new BehaviorSubject<number>(0);
    readonly secondsElapsed$ = this.secondsElapsed.asObservable();
    protected readonly totalTimeLoggedToIssue = new BehaviorSubject<number | undefined>(0);
    readonly totalTimeLoggedToIssue$ = this.totalTimeLoggedToIssue.asObservable();
    protected readonly originalEstimate = new BehaviorSubject<number | null | undefined>(undefined);
    readonly originalEstimate$ = this.originalEstimate.asObservable();

    protected readonly timerCanceled = new Subject<TimerCanceledEvent>();
    readonly timerCanceled$ = this.timerCanceled.asObservable();
    protected readonly timerStarted = new Subject<TimeEntryModel>();
    readonly timerStarted$ = this.timerStarted.asObservable();
    protected readonly timerStopped = new Subject<TimeEntryModel>();
    readonly timerStopped$ = this.timerStopped.asObservable();

    protected readonly descriptionUpdateRequested = new Subject<string>();
    protected readonly descriptionUpdateInProgress = new BehaviorSubject<boolean>(false);
    protected readonly timerStopRequested = new BehaviorSubject<boolean>(false);
    protected readonly timerCancelRequested = new BehaviorSubject<boolean>(false);
    protected readonly unsubscribeSubject = new Subject<void>();

    private timeLoggedForIssue?: TimeLoggedForIssueModel;
    private updateSubscription?: Subscription;

    constructor(
        public zone: NgZone,
        private signalRService: TimerSignalrService,
        private projectsService: ProjectsService,
        private issueServiceProxy: IssueServiceProxy
    ) {
        this.registerEvents();

        this.descriptionUpdateRequested
            .pipe(
                takeUntil(this.unsubscribeSubject),
                withLatestFrom(this.isRunning$),
                filter(([, running]) => running),
                tap(() => this.descriptionUpdateInProgress.next(true)),
                debounceTime(1000),
                distinctUntilChanged(),
                switchMap(([description]) =>
                    from(this.signalRService.updateDescription(description)).pipe(
                        map((response) => ({ success: true as const, data: response })),
                        catchError((error) => of({ success: false as const, error }))
                    )
                )
            )
            .subscribe({
                next: (result) => {
                    if (!result.success) {
                        abp.notify.error((result as { success: false; error: string }).error);
                    }
                    this.descriptionUpdateInProgress.next(false);
                },
                error: (error) => abp.notify.error(error)
            });

        combineLatest([this.timerStopRequested, this.descriptionUpdateInProgress])
            .pipe(
                takeUntil(this.unsubscribeSubject),
                filter(([stopRequested, descriptionUpdateInProgress]) => stopRequested && !descriptionUpdateInProgress),
                switchMap(() =>
                    from(this.signalRService.stopTimer()).pipe(
                        map((response) => ({ success: true as const, data: response })),
                        catchError((error) => of({ success: false as const, error }))
                    )
                )
            )
            .subscribe({
                next: (result) => {
                    if (result.success) {
                        this.timerStopped.next(result.data);
                        this.resetTimer();
                    } else {
                        abp.notify.error((result as { success: false; error: string }).error);
                    }
                    this.isWaiting.next(false);
                    this.timerStopRequested.next(false);
                },
                error: (error) => abp.notify.error(error)
            });

        combineLatest([this.timerCancelRequested, this.descriptionUpdateInProgress])
            .pipe(
                takeUntil(this.unsubscribeSubject),
                filter(
                    ([cancelRequested, descriptionUpdateInProgress]) => cancelRequested && !descriptionUpdateInProgress
                ),
                switchMap(() =>
                    from(this.signalRService.cancelTimer()).pipe(
                        map((response) => ({ success: true as const, data: response })),
                        catchError((error) => of({ success: false as const, error }))
                    )
                )
            )
            .subscribe({
                next: (result) => {
                    if (result.success) {
                        const timeEntryId = this.timeEntryId.getValue();
                        this.timerCanceled.next({ timeEntryId });
                        this.resetTimer();
                    } else {
                        abp.notify.error((result as { success: false; error: string }).error);
                    }
                    this.isWaiting.next(false);
                    this.timerCancelRequested.next(false);
                },
                error: (error) => abp.notify.error(error)
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject.next(void 0);
        this.unsubscribeSubject.complete();
    }

    private registerEvents(): void {
        abp.event.on(TimerEventId.Connected, (currentTimeEntry: TimeEntryModel) => {
            this.zone.run(() => {
                if (currentTimeEntry) {
                    void this.initTimer(currentTimeEntry);
                }
            });
        });

        abp.event.on(TimerEventId.Disconnected, () => {
            this.zone.run(() => {
                this.stopUpdating();
            });
        });

        abp.event.on(TimerEventId.Reconnected, async (currentTimeEntry: TimeEntryModel) => {
            await this.zone.run(async () => {
                if (currentTimeEntry) {
                    await this.initTimer(currentTimeEntry);
                } else {
                    this.resetTimer();
                }
            });
        });

        abp.event.on(TimerEventId.TimerStarted, (timeEntry: TimeEntryModel) => {
            void this.zone.run(async () => {
                await this.initTimer(timeEntry);
                this.timerStarted.next(timeEntry);
            });
        });

        abp.event.on(TimerEventId.TimerStopped, (timeEntry: TimeEntryModel) => {
            this.zone.run(() => {
                this.timerStopped.next(timeEntry);
                this.resetTimer();
            });
        });

        abp.event.on(TimerEventId.TimerCanceled, () => {
            this.zone.run(() => {
                const timeEntryId = this.timeEntryId.getValue();
                this.timerCanceled.next({ timeEntryId });
                this.resetTimer();
            });
        });

        abp.event.on(TimerEventId.DescriptionUpdated, (description: string) => {
            this.zone.run(() => {
                this.description.next(description);
            });
        });

        abp.event.on(TimerEventId.IssueUpdated, (issueId: number | undefined) => {
            void this.zone.run(async () => await this.setIssue(issueId));
        });

        abp.event.on(TimerEventId.ProjectUpdated, (projectId: number | undefined) => {
            void this.zone.run(async () => {
                await this.setProject(projectId);
            });
        });

        abp.event.on(TimerEventId.IsBillableUpdated, (isBillable: boolean) => {
            this.zone.run(() => {
                this.isBillable.next(isBillable);
            });
        });

        abp.event.on(TimerEventId.StartTimeAdjusted, (startTime: DateTime) => {
            this.zone.run(() => {
                this.startTime.next(startTime);
            });
        });

        abp.event.on(TimerEventId.StopTimeAdjusted, (stopTime: DateTime) => {
            this.zone.run(() => {
                this.stopTime.next(stopTime);
            });
        });

        abp.event.on(TimerEventId.VoqServiceUpdated, (voqServiceId: number | undefined) => {
            this.zone.run(() => {
                this.voqServiceId.next(voqServiceId);
            });
        });

        abp.event.on(TimerEventId.TimeLoggedForIssueChanged, (timeLogged: TimeLoggedForIssueModel) => {
            this.zone.run(() => {
                const currentIssueId = this.issueId.getValue();
                if (currentIssueId === timeLogged.issueId) {
                    this.setTimeLoggedForIssue(timeLogged);
                }
            });
        });

        abp.event.on(TimerEventId.TimeEntryUpdated, (timeEntry: TimeEntryModel) => {
            void this.zone.run(async () => {
                if (this.timeEntryId.getValue() === timeEntry.id && this.isRunning.getValue() === true) {
                    await this.initTimer(timeEntry);
                }
            });
        });
    }

    private serializeCurrentTimer(): Observable<TimeEntryBindingModel> {
        return zip(
            this.timeEntryId,
            this.description,
            this.issueId,
            this.projectId,
            this.voqServiceId,
            this.startTime,
            this.stopTime,
            this.isBillable
        ).pipe(
            map(([id, description, issueId, projectId, voqServiceId, startTime, stopTime, isBillable]) =>
                TimeEntryBindingModel.fromJS({
                    id,
                    description,
                    issueId,
                    projectId,
                    voqServiceId,
                    startTime,
                    stopTime,
                    isBillable
                })
            ),
            first()
        );
    }

    async startTimer(): Promise<void> {
        const waiting = await firstValueFrom(this.isWaiting);
        if (waiting) {
            return;
        }
        this.isWaiting.next(true);
        try {
            const timeEntry = await firstValueFrom(this.serializeCurrentTimer());
            await this.startTimerForTimeEntry(timeEntry);
        } finally {
            this.isWaiting.next(false);
        }
    }

    async resumeTimer(timeEntry: TimeEntryBindingModel): Promise<void> {
        const waiting = await firstValueFrom(this.isWaiting);
        if (waiting) {
            return;
        }
        this.isWaiting.next(true);
        try {
            await this.startTimerForTimeEntry(timeEntry);
        } finally {
            this.isWaiting.next(false);
        }
    }

    private async startTimerForTimeEntry(timeEntry: TimeEntryBindingModel): Promise<void> {
        const result = await this.signalRService.startTimer(timeEntry);
        if (result.oldTimeEntry) {
            this.timerStopped.next(result.oldTimeEntry);
        }
        await this.initTimer(result.newTimeEntry);
        this.timerStarted.next(result.newTimeEntry);
    }

    async stopTimer(): Promise<void> {
        const waiting = await firstValueFrom(this.isWaiting);
        if (waiting) {
            return;
        }
        this.isWaiting.next(true);
        this.timerStopRequested.next(true);
    }

    async cancelTimer(): Promise<void> {
        const waiting = await firstValueFrom(this.isWaiting);
        if (waiting) {
            return;
        }
        this.isWaiting.next(true);
        this.timerCancelRequested.next(true);
    }

    async adjustStartTime(time: Date, forceOverwriteIds?: Array<number>): Promise<TimeEntryModel> {
        const startTime = DateTime.fromJSDate(time);
        const stopTime = this.stopTime.getValue();
        if (stopTime && startTime > stopTime) {
            throw new Error("Start time cannot be later than stop time.");
        }
        let result: TimeEntryModel;
        if (await firstValueFrom(this.isRunning$)) {
            result = await this.signalRService.adjustStartTime(startTime, forceOverwriteIds);
        }

        this.startTime.next(startTime);
        this.evaluateCalculatedValues();
        return result;
    }

    async updateDescription(description: string): Promise<void> {
        this.description.next(description);
        this.descriptionUpdateRequested.next(description);
    }

    async updateIssue(issueId: number | undefined): Promise<void> {
        if (issueId === (await firstValueFrom(this.issueId$))) {
            return;
        }

        if (await firstValueFrom(this.isRunning$)) {
            await this.signalRService.updateIssue(issueId);
        }

        await this.setIssue(issueId);

        if (await this.getProjectIsBillable(this.projectId.value)) {
            await this.updateIsBillable(true);
        }
    }

    async updateProject(projectId: number | undefined): Promise<void> {
        if (projectId === (await firstValueFrom(this.projectId$))) {
            return;
        }

        if (await firstValueFrom(this.isRunning$)) {
            await this.signalRService.updateProject(projectId);
        }

        await this.setProject(projectId);
        await this.setDefaultProjectVoqService(projectId);

        if (await this.getProjectIsBillable(this.projectId.value)) {
            await this.updateIsBillable(true);
        }
    }

    async updateVoqService(voqServiceId: number | undefined): Promise<void> {
        if (
            this.projectId.value &&
            voqServiceId &&
            !(await this.projectHasVoqService(this.projectId.value, voqServiceId))
        ) {
            voqServiceId = this.voqServiceId.value;
        }

        if (voqServiceId === (await firstValueFrom(this.voqServiceId$))) {
            return;
        }

        if ((await firstValueFrom(this.isRunning$)) && this.projectId.value) {
            await this.signalRService.updateVoqService(voqServiceId);
        }

        this.voqServiceId.next(voqServiceId);
    }

    async updateIsBillable(isBillable: boolean): Promise<void> {
        if (isBillable === (await firstValueFrom(this.isBillable$))) {
            return;
        }

        if (await firstValueFrom(this.isRunning$)) {
            await this.signalRService.updateIsBillable(isBillable);
        }

        this.isBillable.next(isBillable);
    }

    async initTimer(timeEntry: TimeEntryModel): Promise<void> {
        this.stopUpdating();

        this.timeEntryId.next(timeEntry.id);
        this.timerId.next(timeEntry.timerId);
        this.description.next(timeEntry.description);
        this.startTime.next(DateTime.fromISO(timeEntry.startTime?.toString()));
        this.voqServiceId.next(timeEntry.voqService?.id);
        this.isBillable.next(timeEntry.isBillable);
        this.secondsElapsed.next(timeEntry.durationInSeconds);
        this.setTimeLoggedForIssue(timeEntry.issue?.timeLogged);
        this.originalEstimate.next(timeEntry.issue?.originalEstimateInSeconds);
        this.evaluateCalculatedValues();
        await this.setProject(timeEntry.project?.id);
        await this.setIssue(timeEntry.issue?.id);

        this.startUpdating();

        this.isRunning.next(true);
    }

    resetTimer(): void {
        this.stopUpdating();

        this.timeEntryId.next(undefined);
        this.timerId.next(undefined);
        this.isRunning.next(false);
        this.isWaiting.next(false);
        this.secondsElapsed.next(0);
        this.description.next(undefined);
        this.resetIssue();
        this.projectId.next(undefined);
        this.voqServiceId.next(undefined);
        this.isBillable.next(true);
        this.startTime.next(undefined);
        this.stopTime.next(undefined);
    }

    private startUpdating(): void {
        this.updateSubscription = interval(this.UPDATE_INTERVAL).subscribe({
            next: () => this.evaluateCalculatedValues()
        });
    }

    private evaluateCalculatedValues(): void {
        const startTime = this.startTime.getValue();
        this.secondsElapsed.next(startTime?.diffNow().negate().as("seconds"));
        this.totalTimeLoggedToIssue.next(this.calculateTotalTimeLoggedToIssue());
    }

    private calculateTotalTimeLoggedToIssue(): number | undefined {
        return this.timeLoggedForIssue
            ? this.timeLoggedForIssue.totalSeconds +
                  this.timeLoggedForIssue.asOf.diffNow().negate().as("seconds") *
                      this.timeLoggedForIssue.totalRunningTimers
            : undefined;
    }

    private stopUpdating(): void {
        if (this.updateSubscription && !this.updateSubscription.closed) {
            this.updateSubscription.unsubscribe();
        }
    }

    private async setIssue(issueId: number | undefined): Promise<void> {
        const issue = issueId ? await firstValueFrom(this.issueServiceProxy.getSummaryById(issueId)) : null;
        this.issueId.next(issue?.id);
        this.originalEstimate.next(issue?.originalEstimateInSeconds);
        this.setTimeLoggedForIssue(issue?.timeLogged);
        if (issue !== null && issue.id > 0) {
            this.projectId.next(issue.projectId);
            await this.setDefaultProjectVoqService(issue.projectId);
        }
    }

    private resetIssue(): void {
        this.issueId.next(undefined);
        this.originalEstimate.next(undefined);
        this.setTimeLoggedForIssue(undefined);
    }

    private setTimeLoggedForIssue(timeLoggedForIssue: TimeLoggedForIssueModel | undefined): void {
        this.timeLoggedForIssue = timeLoggedForIssue;
        this.totalTimeLoggedToIssue.next(timeLoggedForIssue?.totalSeconds);
    }

    private async setProject(projectId: number | undefined): Promise<void> {
        this.projectId.next(projectId);
        this.resetIssue();
    }

    private async getProjectIsBillable(projectId: number | undefined): Promise<boolean> {
        if (!projectId) {
            return false;
        }

        const project = await firstValueFrom(this.projectsService.getCachedProjectById(projectId));
        return project?.isBillable ?? false;
    }

    private async setDefaultProjectVoqService(projectId: number | undefined): Promise<void> {
        if (!projectId) {
            return;
        }

        const project = await firstValueFrom(this.projectsService.getCachedProjectById(projectId));

        if (this.voqServiceId.value) {
            const projectHasVoqService = !!project?.voqServiceIds?.find((id) => id === this.voqServiceId.value);
            if (projectHasVoqService && this.isRunning.value) {
                await this.signalRService.updateVoqService(this.voqServiceId.value);
            } else if (!projectHasVoqService) {
                this.voqServiceId.next(undefined);
            }
        }

        if (!this.voqServiceId.value && project?.defaultVoqServiceId) {
            await this.updateVoqService(project?.defaultVoqServiceId);
        }
    }

    private async projectHasVoqService(
        projectId: number | undefined,
        voqServiceId: number | undefined
    ): Promise<boolean> {
        if (!projectId || !voqServiceId) {
            return false;
        }

        const project = await firstValueFrom(this.projectsService.getCachedProjectById(projectId));
        return !!project?.voqServiceIds?.find((id) => id === voqServiceId);
    }
}

interface TimerCanceledEvent {
    timeEntryId?: number;
}
