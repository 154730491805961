<div
    appBsModal
    #changeProfilePictureModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
    [config]="{ backdrop: 'static' }"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #changeProfilePictureModalForm="ngForm" (ngSubmit)="save()">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{ "ChangeProfilePicture" | localize }}</span>
                    </h5>
                    <button type="button" class="btn-close" [attr.aria-label]="l('Close')" (click)="close()"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-5" *ngIf="canUseGravatar()">
                        <label class="form-check form-check-custom form-check-solid py-1">
                            <input
                                id="Profile_UseGravatarProfilePicture"
                                type="checkbox"
                                name="UseGravatarProfilePicture"
                                [(ngModel)]="useGravatarProfilePicture"
                                class="form-check-input"
                            />
                            <span class="form-check-label">
                                {{ "UseGravatarProfilePicture" | localize }}
                            </span>
                        </label>
                    </div>
                    <div class="mb-5">
                        <div class="input-group">
                            <div class="custom-file">
                                <input
                                    type="file"
                                    id="uploadProfilePicture"
                                    (change)="fileChangeEvent($event)"
                                    [disabled]="useGravatarProfilePicture"
                                />
                                <label
                                    class="custom-file-label"
                                    #uploadProfilePictureInputLabel
                                    for="uploadProfilePicture"
                                >
                                    {{ "ChooseAFile" | localize }}
                                </label>
                            </div>
                            <span class="form-text text-muted col-lg-12" [hidden]="useGravatarProfilePicture">
                                {{ "ProfilePicture_Change_Info" | localize: maxProfilPictureBytesUserFriendlyValue }}
                            </span>
                        </div>
                    </div>
                    <image-cropper
                        [imageChangedEvent]="imageChangedEvent"
                        [maintainAspectRatio]="true"
                        [aspectRatio]="4 / 4"
                        [resizeToWidth]="128"
                        format="png"
                        output="base64"
                        (imageCropped)="imageCroppedFile($event)"
                        [hidden]="useGravatarProfilePicture"
                    ></image-cropper>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light-primary fw-bold" (click)="close()" [disabled]="saving">
                        {{ "Cancel" | localize }}
                    </button>
                    <button
                        type="submit"
                        class="btn btn-primary fw-bold"
                        [disabled]="!changeProfilePictureModalForm.form.valid || saving"
                    >
                        <span>{{ "Save" | localize }}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
