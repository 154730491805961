export function getTimerWindowHeight(isExpanded: boolean): number {
    const minHeight = 248;
    const maxHeight = 1000;
    if (!isExpanded) {
        return minHeight;
    }

    const suggestedHeight = Math.floor(window.screen.height * 0.65);
    return Math.max(minHeight, Math.min(maxHeight, suggestedHeight));
}

export function openTimerPopoutWindow(): void {
    const defaultHeight = getTimerWindowHeight(true);
    const timerWindow = window.open("", "timer", `width=880,height=${defaultHeight}`);
    timerWindow?.focus();
    if (timerWindow !== null && timerWindow.location.pathname !== "/timer") {
        timerWindow.location = "/timer";
    }
}
