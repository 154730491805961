import { Directive, OnDestroy, OnInit } from "@angular/core";
import { Dropdown } from "primeng/dropdown";
import { Subject, takeUntil } from "rxjs";

@Directive({
    selector: "p-dropdown[filter]"
})
export class AutoFocusDropdownFilterResultsDirective implements OnInit, OnDestroy {
    private readonly unsubscribeSubject$ = new Subject<void>();

    constructor(private host: Dropdown) {}

    ngOnInit(): void {
        this.host.onFilter.pipe(takeUntil(this.unsubscribeSubject$)).subscribe(() => {
            this.host.changeFocusedOptionIndex({}, 0);
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject$.next(void 0);
        this.unsubscribeSubject$.complete();
    }
}
