<div [@routerTransition] class="d-flex flex-column flex-root app-root" id="kt_app_root">
    <!--begin::Page-->
    <div class="app-page flex-column flex-column-fluid row-gap-4" id="kt_app_page">
        <!--begin::Header-->
        <div id="kt_app_header" class="app-header">
            <!--begin::Header container-->
            <div
                class="app-container container-xxl d-flex align-items-stretch justify-content-between flex-wrap flex-lg-nowrap row-gap-3"
                id="kt_app_header_container"
            >
                <!--begin::sidebar mobile toggle-->
                <div class="d-flex align-items-center d-lg-none ms-n2 me-2 order-1" title="Show sidebar menu">
                    <div class="btn btn-icon btn-active-color-primary w-35px h-35px" id="kt_app_sidebar_mobile_toggle">
                        <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                    fill="currentColor"
                                ></path>
                                <path
                                    opacity="0.3"
                                    d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                    fill="currentColor"
                                ></path>
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                </div>
                <!--end::sidebar mobile toggle-->
                <!--begin::Mobile logo-->
                <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0 order-2">
                    <default-logo [customHrefClass]="'d-lg-none'" [skin]="getMobileMenuSkin()"></default-logo>
                </div>
                <!--end::Mobile logo-->
                <!--begin::Header wrapper-->
                <div
                    class="d-flex align-items-stretch justify-content-between flex-lg-grow-1 order-3 order-lg-4"
                    id="kt_app_header_wrapper"
                >
                    <!--begin::Menu wrapper-->
                    <div
                        *ngIf="appSession.theme.isTopMenuUsed"
                        class="app-header-menu app-header-mobile-drawer align-items-stretch"
                        data-kt-drawer="true"
                        data-kt-drawer-name="app-header-menu"
                        data-kt-drawer-activate="{default: true, lg: false}"
                        data-kt-drawer-overlay="true"
                        data-kt-drawer-width="225px"
                        data-kt-drawer-direction="end"
                        data-kt-drawer-toggle="#kt_app_header_menu_toggle"
                        data-kt-swapper="true"
                        data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                        data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
                    >
                        <top-bar-menu></top-bar-menu>
                    </div>
                    <div *ngIf="!appSession.theme.isTopMenuUsed">&nbsp;</div>
                    <!--end::Menu wrapper-->
                    <!--begin::Navbar-->
                    <div class="app-navbar flex-shrink-0">
                        <menu-search-bar
                            class="d-none d-lg-block align-self-center"
                            *ngIf="currentTheme.baseSettings.menu.searchActive"
                        ></menu-search-bar>
                        <active-delegated-users-combo *ngIf="!installationMode"></active-delegated-users-combo>
                        <subscription-notification-bar></subscription-notification-bar>
                        <quick-theme-selection *ngIf="false"></quick-theme-selection>
                        <language-switch-dropdown *ngIf="false"></language-switch-dropdown>
                        <header-notifications *ngIf="false"></header-notifications>
                        <chat-toggle-button></chat-toggle-button>
                        <!-- <toggle-dark-mode [isDarkModeActive]="isDarkModeActive()"></toggle-dark-mode> -->
                        <user-menu></user-menu>
                    </div>
                    <!--end::Navbar-->
                </div>

                <div class="d-flex align-items-center order-4 order-lg-3">
                    <voqaratheme1-header></voqaratheme1-header>
                </div>
                <!--end::Header wrapper-->
            </div>
            <!--end::Header container-->
        </div>
        <!--end::Header-->
        <!--begin::Wrapper-->
        <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
            <!--begin::sidebar-->
            <div
                id="kt_app_sidebar"
                class="app-sidebar flex-column"
                data-kt-drawer="true"
                data-kt-drawer-name="app-sidebar"
                data-kt-drawer-activate="{default: true, lg: false}"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="225px"
                data-kt-drawer-direction="start"
                data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle"
            >
                <!--begin::Logo-->
                <div class="app-sidebar-logo px-6" id="kt_app_sidebar_logo">
                    <!--begin::Logo image-->
                    <voqaratheme1-brand></voqaratheme1-brand>
                    <!--end::Logo image-->
                    <!--begin::Sidebar toggle-->
                    <div
                        *ngIf="appSession.theme.baseSettings.menu.allowAsideMinimizing"
                        id="kt_app_sidebar_toggle"
                        class="app-sidebar-toggle btn btn-icon h-30px w-30px position-absolute top-50 translate-middle rotate"
                        data-kt-toggle="true"
                        data-kt-toggle-state="active"
                        data-kt-toggle-target="body"
                        data-kt-toggle-name="app-sidebar-minimize"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr079.svg-->
                        <span class="svg-icon svg-icon-2 rotate-180">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                    stroke="#747094"
                                    stroke-width="1.5"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M13.2602 15.5297L9.74023 11.9997L13.2602 8.46973"
                                    stroke="#747094"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Sidebar toggle-->
                </div>
                <!--end::Logo-->
                <!--begin::sidebar menu-->
                <div class="app-sidebar-menu overflow-hidden flex-column-fluid">
                    <!--begin::Menu wrapper-->
                    <div
                        id="kt_app_sidebar_menu_wrapper"
                        class="app-sidebar-wrapper hover-scroll-overlay-y my-5"
                        data-kt-scroll="true"
                        data-kt-scroll-activate="true"
                        data-kt-scroll-height="auto"
                        data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
                        data-kt-scroll-wrappers="#kt_app_sidebar_menu"
                        data-kt-scroll-offset="5px"
                        data-kt-scroll-save-state="true"
                    >
                        <side-bar-menu></side-bar-menu>
                    </div>
                    <!--end::Menu wrapper-->
                </div>
                <!--end::sidebar menu-->
            </div>
            <!--end::sidebar-->
            <!--begin::Main-->
            <div class="app-main flex-column flex-row-fluid" id="kt_app_main">
                <!--begin::Content wrapper-->
                <div class="d-flex flex-column flex-column-fluid">
                    <router-outlet></router-outlet>
                </div>
                <!--end::Content wrapper-->
                <!--begin::Footer-->
                <div id="kt_app_footer" class="app-footer" *ng-if="false">
                    <!--begin::Footer container-->
                    <footer-bar></footer-bar>
                    <!--end::Footer container-->
                </div>
                <!--end::Footer-->
            </div>
            <!--end:::Main-->
        </div>
        <!--end::Wrapper-->
    </div>
    <!--end::Page-->
</div>
