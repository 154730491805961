<div
    bsModal
    #modal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modal"
    aria-hidden="true"
    [config]="{ backdrop: 'static' }"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    {{ l("SelectProjects") }}
                </h4>
                <button
                    type="button"
                    class="btn-close"
                    data-dismiss="modal"
                    aria-hidden="true"
                    (click)="modal.hide()"
                ></button>
            </div>
            <div class="modal-body">
                <h5 *ngIf="projects.length">{{ l("Projects") }}</h5>
                <div class="table-wrapper mb-6">
                    <p-table [value]="projects" [(selection)]="selectedProjects">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>
                                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                </th>
                                <th>Name</th>
                                <th>Client</th>
                                <th>Type</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-project>
                            <tr class="main-row">
                                <td>
                                    <p-tableCheckbox [value]="project"></p-tableCheckbox>
                                </td>
                                <td>{{ project.name }}</td>
                                <td>{{ project.clientName }}</td>
                                <td><span class="badge rounded-pill bg-secondary text-dark">N/A</span></td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="6">
                                    <p class="no-item">No unselected projects</p>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <div class="modal-footer">
                <button
                    type="submit"
                    class="btn btn-primary blue"
                    [disabled]="!selectedProjects.length"
                    (click)="saveSelection()"
                >
                    <span>{{ l("Save") }}</span>
                </button>
            </div>
        </div>
    </div>
</div>
