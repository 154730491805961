import { AddressServiceProxy, StateDto } from '@shared/service-proxies/service-proxies';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class AddressService {

    private _addressServiceProxy: AddressServiceProxy;

    constructor(addressServiceProxy: AddressServiceProxy) {
        this._addressServiceProxy = addressServiceProxy;
    }

    loadStates(): Observable<StateDto[]> {
        //TODO: replace hard-coded Id when we have ability to select countries
        return this._addressServiceProxy.getStates(220)
          .pipe(
            map(result => result.items.map(state => state)));
    }
}