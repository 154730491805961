import {
    AfterContentInit,
    Component,
    ContentChildren,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { Table, TableService } from "primeng/table";
import { PrimeTemplate } from "primeng/api";
import { PrimengTableHelper } from "@shared/helpers/PrimengTableHelper";

export function tableFactory(tableComponent: InfiniteTableComponent): Table {
    return tableComponent.dataTable;
}

export interface InfiniteTableRequest {
    currentPage: number;
    skipCount: number;
    maxResultCount: number;
    sorting?: string;
}

@Component({
    selector: "app-infinite-table",
    template: `
        <div
            class="table-wrapper"
            [infiniteScrollDisabled]="isLoading || isEndOfList || !initializationComplete"
            infiniteScroll
            [infiniteScrollDistance]="infiniteScrollDistance"
            [infiniteScrollThrottle]="infiniteScrollThrottle"
            (scrolled)="triggerLoadData()"
        >
            <p-table
                #dataTable
                (onLazyLoad)="triggerLoadData(true)"
                (selectionChange)="onSelectionChange.emit($event)"
                (onFilter)="onFilter.emit($event)"
                [(selection)]="selectedItems"
                [value]="data"
                [paginator]="false"
                [lazy]="true"
                [lazyLoadOnInit]="false"
                [loading]="isLoading"
                [resizableColumns]="resizableColumns"
                [rowHover]="rowHover"
                [sortField]="sortField"
                [sortOrder]="sortOrder"
                [dataKey]="dataKey"
            >
                <ng-template pTemplate="header">
                    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
                </ng-template>
                <ng-template pTemplate="body" let-data>
                    <ng-container
                        *ngTemplateOutlet="
                            bodyTemplate;
                            context: { $implicit: data, expanded: dataTable.isRowExpanded(data) }
                        "
                    ></ng-container>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-data *ngIf="rowExpansionTemplate">
                    <ng-container *ngTemplateOutlet="rowExpansionTemplate; context: { $implicit: data }"></ng-container>
                </ng-template>
                <ng-template pTemplate="summary" *ngIf="summaryTemplate">
                    <ng-container *ngTemplateOutlet="summaryTemplate"></ng-container>
                </ng-template>
                <ng-template pTemplate="emptymessage" *ngIf="emptyMessageTemplate">
                    <ng-container *ngTemplateOutlet="emptyMessageTemplate"></ng-container>
                </ng-template>
            </p-table>
        </div>
    `,
    providers: [
        TableService,
        {
            provide: Table,
            useFactory: tableFactory,
            deps: [InfiniteTableComponent]
        }
    ]
})
export class InfiniteTableComponent extends AppComponentBase implements OnChanges, OnInit, AfterContentInit {
    @ViewChild("dataTable", { static: true }) dataTable!: Table;

    @Input() isLoading = false;
    @Input() data = [];
    @Input() dataKey?: string;
    @Input() infiniteScrollDistance = 2;
    @Input() infiniteScrollThrottle = 50;
    @Input() rowHover = false;
    @Input() sortField?: string;
    @Input() sortOrder = 1;
    @Input() totalRecordsCount = 0;
    @Input() itemsPerPage = 10;
    @Input() initialItemsPerPage = this.itemsPerPage;

    @Output() loadData = new EventEmitter<InfiniteTableRequest>();
    @Output() onSelectionChange = new EventEmitter<unknown>();
    @Output() onFilter = new EventEmitter<unknown>();

    @ContentChildren(PrimeTemplate)
    templates!: QueryList<PrimeTemplate>;
    headerTemplate?: TemplateRef<unknown>;
    bodyTemplate?: TemplateRef<unknown>;
    rowExpansionTemplate?: TemplateRef<unknown>;
    summaryTemplate?: TemplateRef<unknown>;
    emptyMessageTemplate?: TemplateRef<unknown>;
    resizableColumns = false;
    currentPage = 1;
    isEndOfList = false;
    selectedItems = [];
    initializationComplete = false;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        // HACK: Running initial call asynchronously to prevent this.loadData from emitting between lifecycle events
        setTimeout(() => {
            this.triggerLoadData(true);
            this.initializationComplete = true;
        }, 0);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["data"] || changes["totalRecordsCount"]) {
            this.isEndOfList = this.data.length >= this.totalRecordsCount;
        }
    }

    getPrimeTemplateByType(type: string): PrimeTemplate | undefined {
        return this.templates.find((template) => template.getType() === type);
    }

    ngAfterContentInit(): void {
        this.headerTemplate = this.getPrimeTemplateByType("header")?.template;
        this.bodyTemplate = this.getPrimeTemplateByType("body")?.template;
        this.rowExpansionTemplate = this.getPrimeTemplateByType("rowexpansion")?.template;
        this.summaryTemplate = this.getPrimeTemplateByType("summary")?.template;
        this.emptyMessageTemplate = this.getPrimeTemplateByType("emptymessage")?.template;
    }

    triggerLoadData(reset = false): void {
        let recordsToFetch: number;
        if (reset) {
            recordsToFetch = this.initialItemsPerPage ? this.initialItemsPerPage : this.itemsPerPage;
            this.currentPage = 1;
        } else {
            recordsToFetch = this.itemsPerPage;
            this.currentPage++;
        }
        const skipCount =
            (this.currentPage - 1) * this.itemsPerPage -
            (this.currentPage > 1 && this.initialItemsPerPage ? this.initialItemsPerPage - this.itemsPerPage : 0);
        const sorting = PrimengTableHelper.getSorting(this.dataTable);

        this.loadData.emit({
            currentPage: this.currentPage,
            skipCount,
            maxResultCount: recordsToFetch,
            sorting
        } as InfiniteTableRequest);
    }
}
