import { Component, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormControl, FormArray, FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AppComponentBase } from "@shared/common/app-component-base";
import { NameValueDtoOfInt32, NameValueDtoOfInt64 } from "@shared/service-proxies/service-proxies";
import { Table } from "primeng/table";

interface ProjectStaffMembersDetailForm {
    rowId: FormControl<number | null>;
    staffMember: FormControl<NameValueDtoOfInt32 | null | undefined>;
    billRate: FormControl<number | null>;
}

interface ProjectStaffMembersForm {
    staffMembers: FormArray<FormGroup<ProjectStaffMembersDetailForm>>;
}

@Component({
    selector: "app-assigned-users",
    templateUrl: "./assigned-users.component.html"
})
export class AssignedUsersComponent extends AppComponentBase implements OnChanges {
    @ViewChild("staffMembersTable") staffMembersTable!: Table;

    @Input() availableStaffMembers: NameValueDtoOfInt64[] = [];
    @Input() assignedStaffMembers: NameValueDtoOfInt64[] = [];
    @Output() assignedStaffMembersChange = new EventEmitter<NameValueDtoOfInt64[]>();
    @Input() isDisabled = false;

    isUpdatingStaffMembers = false;
    staffMembersForm: FormGroup<ProjectStaffMembersForm>;

    get projectStaffMembersControl(): FormArray<FormGroup<ProjectStaffMembersDetailForm>> {
        return this.staffMembersForm.controls.staffMembers as FormArray<FormGroup<ProjectStaffMembersDetailForm>>;
    }

    get assignUserButtonIsDisabled(): boolean {
        return (
            this.projectStaffMembersControl.length >= this.availableStaffMembers.length || this.isUpdatingStaffMembers
        );
    }

    constructor(
        injector: Injector,
        private formBuilder: FormBuilder
    ) {
        super(injector);

        this.staffMembersForm = this.formBuilder.group<ProjectStaffMembersForm>({
            staffMembers: this.formBuilder.array<FormGroup<ProjectStaffMembersDetailForm>>(
                [],
                [Validators.required, Validators.minLength(1)]
            )
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.assignedStaffMembers) {
            this.prepStaffMembersTable();
        }
    }

    getFilteredStaffMembers(staffMemberId: number): NameValueDtoOfInt64[] {
        return this.availableStaffMembers.filter(
            (asm) =>
                this.projectStaffMembersControl.controls.map((c) => c.value.staffMember?.value).indexOf(asm.value) ===
                    -1 || asm.value === staffMemberId
        );
    }

    prepStaffMembersTable(): void {
        this.projectStaffMembersControl.clear();
        this.assignedStaffMembers.forEach((x) => {
            this.projectStaffMembersControl.push(
                this.formBuilder.group<ProjectStaffMembersDetailForm>({
                    rowId: new FormControl(x.value),
                    staffMember: new FormControl(this.availableStaffMembers.find((asm) => asm.value === x.value)),
                    billRate: new FormControl(0)
                })
            );
        });
    }

    prepNewStaffMemberRow(): void {
        this.isUpdatingStaffMembers = true;

        const addRowGroup = this.formBuilder.group<ProjectStaffMembersDetailForm>({
            rowId: new FormControl(null),
            staffMember: new FormControl<NameValueDtoOfInt32>(null, Validators.required),
            billRate: new FormControl(0)
        });

        this.projectStaffMembersControl.push(addRowGroup);
        this.staffMembersTable.initRowEdit(addRowGroup);
    }

    async addStaffMember(rowData: FormGroup<ProjectStaffMembersDetailForm>): Promise<void> {
        if (rowData.invalid) {
            rowData.controls.staffMember.markAsDirty();
            return;
        }
        if (!rowData.controls.staffMember.value) {
            return;
        }
        this.assignedStaffMembers.push(rowData.controls.staffMember.value);
        this.assignedStaffMembersChange.emit(this.assignedStaffMembers);
        this.prepStaffMembersTable();
        this.isUpdatingStaffMembers = false;
    }

    async deleteStaffMember(rowData: FormGroup<ProjectStaffMembersDetailForm>): Promise<void> {
        this.assignedStaffMembers = this.assignedStaffMembers.filter((x) => x.value !== rowData.controls.rowId.value);
        this.assignedStaffMembersChange.emit(this.assignedStaffMembers);
        this.prepStaffMembersTable();
    }

    cancelAddEditStaffMember(rowData: FormGroup<ProjectStaffMembersDetailForm>): void {
        this.projectStaffMembersControl.removeAt(this.projectStaffMembersControl.controls.indexOf(rowData));
        this.isUpdatingStaffMembers = false;
    }
}
