import { Component, Input } from "@angular/core";

@Component({
    selector: "app-info-tooltip",
    template: `
        <span [pTooltip]="tooltipText">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                class="ms-1 mb-1"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12 0C18.6257 0 24 5.37429 24 12C24 18.6257 18.6257 24 12 24C5.37429 24 0 18.6257 0 12C0 5.37429 5.37429 0 12 0ZM12 1.71429C6.32571 1.71429 1.71429 6.32571 1.71429 12C1.71429 17.6743 6.32571 22.2857 12 22.2857C17.6743 22.2857 22.2857 17.6743 22.2857 12C22.2857 6.32571 17.6743 1.71429 12 1.71429ZM11.9571 5.95714C12.4286 5.95714 12.8143 6.34286 12.8143 6.81429C12.8143 7.28571 12.4286 7.67143 11.9571 7.67143C11.4857 7.67143 11.1 7.28571 11.1 6.81429C11.1 6.34286 11.4857 5.95714 11.9571 5.95714ZM12.8143 17.9571C12.8143 18.4286 12.4286 18.8143 11.9571 18.8143C11.4857 18.8143 11.1 18.4286 11.1 17.9571V10.2429C11.1 9.77143 11.4857 9.38571 11.9571 9.38571C12.4286 9.38571 12.8143 9.77143 12.8143 10.2429V17.9571Z"
                    fill="#D9DBE3"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.8571 6C12.3286 6 12.7143 6.38571 12.7143 6.85714C12.7143 7.32857 12.3286 7.71429 11.8571 7.71429C11.3857 7.71429 11 7.32857 11 6.85714C11 6.38571 11.3857 6 11.8571 6ZM12.7143 18C12.7143 18.4714 12.3286 18.8571 11.8571 18.8571C11.3857 18.8571 11 18.4714 11 18V10.2857C11 9.81429 11.3857 9.42857 11.8571 9.42857C12.3286 9.42857 12.7143 9.81429 12.7143 10.2857V18Z"
                    fill="black"
                />
            </svg>
        </span>
    `
})
export class InfoTooltipComponent {
    @Input() tooltipText!: string;
}
