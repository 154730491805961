import { AbpMultiTenancyService } from "abp-ng2-module";
import { Injectable } from "@angular/core";
import {
    ApplicationInfoDto,
    GetCurrentLoginInformationsOutput,
    SessionServiceProxy,
    TenantLoginInfoDto,
    UserLoginInfoDto,
    UiCustomizationSettingsDto
} from "@shared/service-proxies/service-proxies";

@Injectable()
export class AppSessionService {
    private _user?: UserLoginInfoDto;
    private _impersonatorUser?: UserLoginInfoDto;
    private _tenant?: TenantLoginInfoDto;
    private _impersonatorTenant?: TenantLoginInfoDto;
    private _application?: ApplicationInfoDto;
    private _theme?: UiCustomizationSettingsDto;

    constructor(
        private _sessionService: SessionServiceProxy,
        private _abpMultiTenancyService: AbpMultiTenancyService
    ) {}

    get application(): ApplicationInfoDto | undefined {
        return this._application;
    }

    get user(): UserLoginInfoDto | undefined {
        return this._user;
    }

    get userId(): number | undefined {
        return this.user ? this.user.id : undefined;
    }

    get tenant(): TenantLoginInfoDto | undefined {
        return this._tenant;
    }

    get tenancyName(): string | undefined {
        return this._tenant ? this.tenant?.tenancyName : "";
    }

    get tenantId(): number | undefined {
        return this.tenant ? this.tenant.id : undefined;
    }

    get impersonatorUser(): UserLoginInfoDto | undefined {
        return this._impersonatorUser;
    }
    get impersonatorUserId(): number | undefined {
        return this.impersonatorUser ? this.impersonatorUser.id : undefined;
    }
    get impersonatorTenant(): TenantLoginInfoDto | undefined {
        return this._impersonatorTenant;
    }
    get impersonatorTenancyName(): string | undefined {
        return this.impersonatorTenant ? this.impersonatorTenant.tenancyName : "";
    }
    get impersonatorTenantId(): number | undefined {
        return this.impersonatorTenant ? this.impersonatorTenant.id : undefined;
    }

    get theme(): UiCustomizationSettingsDto | undefined {
        return this._theme;
    }

    set theme(val: UiCustomizationSettingsDto) {
        this._theme = val;
    }

    set application(val: ApplicationInfoDto) {
        this._application = val;
    }

    init(): Promise<UiCustomizationSettingsDto | undefined> {
        return new Promise<UiCustomizationSettingsDto | undefined>((resolve, reject) => {
            this._sessionService
                .getCurrentLoginInformations()
                .toPromise()
                .then(
                    (result: GetCurrentLoginInformationsOutput | undefined) => {
                        this._application = result?.application;
                        this._user = result?.user;
                        this._tenant = result?.tenant;
                        this._theme = result?.theme;
                        this._impersonatorTenant = result?.impersonatorTenant;
                        this._impersonatorUser = result?.impersonatorUser;
                        resolve(result?.theme);
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    }

    getShownLoginName(): string | undefined {
        const userName = this._user?.userName;
        if (!this._abpMultiTenancyService.isEnabled) {
            return userName;
        }

        return (this._tenant ? this._tenant.tenancyName : ".") + "\\" + userName;
    }

    changeTenantIfNeeded(tenantId?: number): boolean {
        if (this.isCurrentTenant(tenantId)) {
            return false;
        }

        abp.multiTenancy.setTenantIdCookie(tenantId);
        location.reload();
        return true;
    }

    private isCurrentTenant(tenantId?: number) {
        let isTenant = tenantId !== undefined && tenantId > 0;

        if (!isTenant && !this.tenant) {
            // this is host
            return true;
        }

        if (!tenantId && this.tenant) {
            return false;
        } else if (tenantId && (!this.tenant || this.tenant.id !== tenantId)) {
            return false;
        }

        return true;
    }
}
