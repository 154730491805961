import { CommonModule } from "@angular/common";
import { Component, computed, Injector, Input, OnChanges, OnDestroy, OnInit, Signal, SimpleChanges } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { UtilsModule } from "@shared/utils/utils.module";
import { ProgressCircle } from "@timer/models/progress-circle";
import { TimerService } from "@timer/services/timer.service";
import { NgCircleProgressModule } from "ng-circle-progress";
import { BehaviorSubject, combineLatest, filter, Subject, takeUntil } from "rxjs";

@Component({
    standalone: true,
    selector: "app-task-progress",
    templateUrl: "./task-progress.component.html",
    styleUrls: ["./task-progress.component.scss"],
    imports: [CommonModule, NgCircleProgressModule, UtilsModule]
})
export class TaskProgressComponent extends AppComponentBase implements OnInit, OnDestroy, OnChanges {
    @Input() isRunningTimer = false;
    @Input() percentComplete = 0;

    private readonly runningTimerUnderEstimate: ProgressCircle = {
        percent: 0,
        color: "#ADE2FF"
    };
    private readonly loggedTimeUnderEstimate: ProgressCircle = {
        percent: 0,
        color: "#33B6FF"
    };
    private readonly runningTimerOverEstimate: ProgressCircle = {
        percent: 0,
        color: "#FF5D5D"
    };
    private readonly loggedTimeOverEstimate: ProgressCircle = {
        percent: 0,
        color: "#F44242"
    };

    readonly progressCircles: Signal<ProgressCircle[]> = computed(() => {
        if (this.isRunningTimer) {
            return [
                this.runningTimerUnderEstimate,
                this.loggedTimeUnderEstimate,
                this.runningTimerOverEstimate,
                this.loggedTimeOverEstimate
            ];
        } else {
            return [
                this.loggedTimeUnderEstimate,
                this.loggedTimeOverEstimate
            ];
        }
    });

    readonly duration$ = new BehaviorSubject<number>(0);
    readonly durationIsFocused$ = new BehaviorSubject<boolean>(false);

    totalSeconds = 0;

    private readonly unsubscribeSubject$ = new Subject<void>();

    constructor(
        public timerService: TimerService,
        injector: Injector
    ) {
        super(injector);
    }

    ngOnInit(): void {
        if (this.isRunningTimer) {
            this.subscribeToTimerEvents();
        }
    }

    override ngOnDestroy(): void {
        this.unsubscribeSubject$.next(void 0);
        this.unsubscribeSubject$.complete();
        super.ngOnDestroy();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.percentComplete) {
            this.calculateCircleValues();
        }
    }

    private calculateCircleValues(): void {
        this.loggedTimeUnderEstimate.percent = this.percentComplete;
        const overagePercent = this.percentComplete - 100;
        this.loggedTimeOverEstimate.percent = overagePercent < 0 ? 0 : overagePercent;
    }

    subscribeToTimerEvents(): void {
        combineLatest([
            this.timerService.secondsElapsed$,
            this.timerService.totalTimeLoggedToIssue$,
            this.timerService.originalEstimate$
        ])
            .pipe(takeUntil(this.unsubscribeSubject$))
            .subscribe({
                next: ([elapsedSeconds, totalLoggedSeconds, estimate]) => {
                    if (elapsedSeconds !== undefined && totalLoggedSeconds !== undefined && estimate) {
                        this.totalSeconds = totalLoggedSeconds;
                        this.updateProgressCircles(elapsedSeconds, estimate);
                    } else {
                        this.totalSeconds = elapsedSeconds ?? 0;
                        this.runningTimerUnderEstimate.percent = 0;
                        this.loggedTimeUnderEstimate.percent = 0;
                        this.runningTimerOverEstimate.percent = 0;
                        this.loggedTimeOverEstimate.percent = 0;
                    }
                }
            });

        combineLatest([this.timerService.secondsElapsed$, this.durationIsFocused$])
            .pipe(
                takeUntil(this.unsubscribeSubject$),
                filter(([, durationIsFocused]) => !durationIsFocused)
            )
            .subscribe({ next: ([secondsElapsed]) => this.duration$.next(secondsElapsed) });
    }

    private updateProgressCircles(elapsedSeconds: number, estimate: number): void {
        const runningTotalPercent = (this.totalSeconds / estimate) * 100;
        const loggedTotalPercent = ((this.totalSeconds - elapsedSeconds) / estimate) * 100;

        this.runningTimerUnderEstimate.percent = runningTotalPercent > 100 ? 100 : runningTotalPercent;
        this.loggedTimeUnderEstimate.percent = loggedTotalPercent > 100 ? 100 : loggedTotalPercent;
        this.runningTimerOverEstimate.percent = runningTotalPercent > 100 ? runningTotalPercent % 100 : 0;

        if (loggedTotalPercent >= 200 || runningTotalPercent > 200) {
            this.loggedTimeOverEstimate.percent = 100;
        } else if (loggedTotalPercent > 100) {
            this.loggedTimeOverEstimate.percent = loggedTotalPercent % 100;
        } else {
            this.loggedTimeOverEstimate.percent = 0;
        }
    }
}
