import { Pipe, PipeTransform } from "@angular/core";
import { DateTime, DurationLikeObject } from "luxon";

@Pipe({
    name: "dateTimeDiff"
})
export class DateTimeDiffPipe implements PipeTransform {
    transform(value: DateTime, to: DateTime, config: { as: keyof DurationLikeObject; abs?: boolean }): number {
        if (!value.isValid || !to.isValid) {
            return 0;
        }
        let result = value.diff(to).as(config.as);
        if (config.abs) {
            result = Math.abs(result);
        }
        return result;
    }
}
