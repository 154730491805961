<div
    [formGroup]="parentForm"
    novalidate
    [class.form-floating]="displayLabel"
    [class.is-invalid]="displayLabel && invalid"
>
    <input
        #descriptionInput
        name="description"
        [class]="inputClass"
        [ngClass]="{ 'ng-invalid': invalid }"
        [placeholder]="placeholder"
        [readonly]="readonly"
        type="text"
        formControlName="description"
        pInputText
        (change)="change.next($event)"
        (keyup)="keyUp.next($event)"
        (keyup.enter)="descriptionInput.blur()"
    />
    <label [for]="descriptionControl" *ngIf="displayLabel">{{ placeholder }}</label>
</div>
