import { Pipe, PipeTransform } from "@angular/core";
import { DateTime } from "luxon";

@Pipe({
    name: "coerceDefaultDateTimeToNow"
})
export class CoerceDefaultDateTimeToNowPipe implements PipeTransform {
    transform(value: DateTime): DateTime {
        return value.year === 0 || value.year === 1 ? DateTime.now() : value;
    }
}
