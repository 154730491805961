import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { NameValueDtoOfInt32 } from "@shared/service-proxies/service-proxies";

@Pipe({ name: "flattenKeyValueList" })
export class FlattenKeyValueListPipe implements PipeTransform {
    constructor(private _sanitizer: DomSanitizer) {}

    transform(items: NameValueDtoOfInt32[], displayCount: number): SafeHtml {
        if (items == null || items === undefined) {
            return "";
        }
        const totalCount = items.length;

        if (totalCount === 0) {
            return "-";
        }

        let result = items
            .filter((item, index) => index < displayCount)
            .map((item) => item.name)
            .join(", ");

        if (totalCount > displayCount) {
            let extraItems = items
                .filter((item, index) => index >= displayCount)
                .map((item) => item.name)
                .join(", ");
            result += ", <u title='" + extraItems + "'>+" + (totalCount - displayCount) + "</u>";
        }
        return this._sanitizer.bypassSecurityTrustHtml(result);
    }
}
