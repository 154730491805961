import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StatusBadgeComponent } from "./status-badge.component";
import { SharedModule } from "@shared/shared.module";
import { UtilsModule } from "@shared/utils/utils.module";

@NgModule({
    declarations: [StatusBadgeComponent],
    exports: [StatusBadgeComponent],
    imports: [CommonModule, SharedModule, UtilsModule]
})
export class StatusBadgeModule {}
