import { Pipe, PipeTransform } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";

@Pipe({ name: "indicateIfRequired" })
export class IndicateIfRequiredPipe implements PipeTransform {
    transform(formControlKey: string, formGroup: FormGroup, indicator = " *"): string {
        const controlKey = formControlKey;
        const formControl = formGroup.get(controlKey);
        if (!formControl) {
            console.error(`Control with key ${controlKey} not found in form group`);
        }
        return formControl?.hasValidator(Validators.required) ? indicator : "";
    }
}
