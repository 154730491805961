import { Directive, Input } from "@angular/core";
import { Validator, NG_VALIDATORS, AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

@Directive({
    selector: "[greaterThan]",
    providers: [{ provide: NG_VALIDATORS, useExisting: GreaterThanValidatorDirective, multi: true }]
})
export class GreaterThanValidatorDirective implements Validator {
    @Input("greaterThan") comparisonControls!: { lesser: string; greater: string };

    validate(control: AbstractControl): ValidationErrors | null {
        return this.comparisonControls
            ? GreaterThanValidatorDirective.validate(this.comparisonControls)(control)
            : null;
    }

    registerOnValidatorChange?(): void {
        throw new Error("Method not implemented.");
    }

    public static validate(comparisonControls: { lesser: string; greater: string }): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            let lesserControl = control.get(comparisonControls.lesser);
            let greaterControl = control.get(comparisonControls.greater);

            if (!lesserControl) {
                throw new Error(`'${comparisonControls.lesser}' was not found in FormGoup.controls`);
            }

            if (!greaterControl) {
                throw new Error(`'${comparisonControls.greater}' was not found in FormGoup.controls`);
            }

            if (lesserControl.value === null || greaterControl.value === null) {
                return null;
            }

            let isGreaterThan = greaterControl.value > lesserControl.value;

            return isGreaterThan
                ? null
                : ({
                      greaterThan: {
                          value: `${comparisonControls.greater} must be greater than ${comparisonControls.lesser}`
                      }
                  } as ValidationErrors);
        };
    }
}
