<ng-container [formGroup]="timeRangeForm">
    <div class="start-date" *ngIf="displayDates">
        <label [for]="startTimeControl" *ngIf="showExtendedForm">{{ l("StartTime") }}</label>
        <app-date-time-picker
            [class.edit-mode]="showEditDetails"
            formControlName="startTime"
            [defaultDate]="workday"
            [displayIcon]="showExtendedForm"
            [displayDate]="showExtendedForm || showEditDetails"
            [displayLabel]="displayFloatingLabels ? l('StartTime') : ''"
            [displayError]="saveFailed"
            [inputClass]="{ 'form-control': true, 'ng-invalid': showExtendedForm && saveFailed }"
            (inputFocusChange)="unitOfWorkActive$.next(true); onTouched()"
            (inputBlur)="unitOfWorkActive$.next(false)"
            (inputKeyupEscape)="resetValues()"
        ></app-date-time-picker>
    </div>
    <div *ngIf="showExtendedForm">
        <label>&nbsp;</label>
        <div class="p4 mx-1">-</div>
    </div>
    <div class="stop-date" *ngIf="!isRunningTimer && displayDates">
        <label [for]="stopTimeControl" *ngIf="showExtendedForm">{{ l("StopTime") }}</label>
        <app-date-time-picker
            [class.edit-mode]="showEditDetails"
            formControlName="stopTime"
            [defaultDate]="workday"
            [displayIcon]="showExtendedForm"
            [displayDate]="showExtendedForm || showEditDetails"
            [displayLabel]="displayFloatingLabels ? l('StopTime') : ''"
            [displayError]="saveFailed"
            [inputClass]="{ 'form-control': true, 'ng-invalid': showExtendedForm && saveFailed }"
            (inputFocusChange)="unitOfWorkActive$.next(true); onTouched()"
            (inputBlur)="unitOfWorkActive$.next(false)"
            (inputKeyupEscape)="resetValues()"
        ></app-date-time-picker>
    </div>
    <div
        class="duration"
        [class.form-floating]="displayFloatingLabels"
        [class.is-invalid]="displayFloatingLabels && durationControl.dirty && saveFailed"
    >
        <label [for]="durationControl" *ngIf="showExtendedForm && !displayFloatingLabels">{{ l("Duration") }}</label>
        <input
            type="text"
            readonly
            [ngClass]="{
                'form-control': showExtendedForm || displayDates,
                'progress-input text-center': !displayDates
            }"
            *ngIf="!showEditDetails; else durationInputTemplate"
            (click)="selectDurationInput($event)"
            value="{{ durationInSeconds | timerDisplayFormat: 'user' }}"
        />
        <ng-template #durationInputTemplate>
            <input
                #durationInput
                class="form-control"
                [ngClass]="{
                    'ng-invalid': showExtendedForm && durationControl.dirty && saveFailed
                }"
                pInputText
                type="text"
                id="duration"
                formControlName="duration"
                (focus)="selectDurationInput($event)"
                (blur)="unitOfWorkActive$.next(false)"
                (keyup.enter)="durationInput.blur()"
                (keyup.esc)="resetValues(); durationInput.blur()"
            />
        </ng-template>
        <label [for]="durationControl" *ngIf="displayFloatingLabels">{{ l("Duration") }}</label>
    </div>
</ng-container>
