import { Injector, ElementRef, Component, OnInit, ViewChild } from "@angular/core";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { ThemesLayoutBaseComponent } from "@app/shared/layout/themes/themes-layout-base.component";
import { UrlHelper } from "@shared/helpers/UrlHelper";
import { AppConsts } from "@shared/AppConsts";
import { DateTimeService } from "@app/shared/common/timing/date-time.service";

@Component({
    templateUrl: "./theme4-layout.component.html",
    selector: "theme4-layout",
    animations: [appModuleAnimation()]
})
export class Theme4LayoutComponent extends ThemesLayoutBaseComponent implements OnInit {
    @ViewChild("ktHeader", { static: true }) ktHeader: ElementRef;

    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;

    constructor(injector: Injector, _dateTimeService: DateTimeService) {
        super(injector, _dateTimeService);
    }

    ngOnInit() {
        this.installationMode = UrlHelper.isInstallUrl(location.href);
    }
}
