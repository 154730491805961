<div class="timer-buttons-wrap" [class.small]="smallIcons">
    <div
        class="timer-buttons"
        [ngClass]="{ 'is-running': isRunning, 'has-hover': enableHover }"
        [class.hover-up]="hoverDirection === 'up'"
        [class.hover-down]="hoverDirection === 'down'"
        [class.hover-auto]="hoverDirection === 'auto'"
        [class.gray-scale]="useGrayScale"
    >
        <div class="timer-button play" (click)="playClick.emit()"></div>
        <div class="timer-button stop" (click)="stopClick.emit()"></div>
        <div class="timer-button pause"></div>
        <div class="timer-button cancel" (click)="cancelClick.emit()"></div>
    </div>
</div>
