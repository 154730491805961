import { Component, Injector, OnInit, Output, EventEmitter, Input, OnDestroy } from "@angular/core";
import { StateDto, CountryDto, AddressViewModel } from "@shared/service-proxies/service-proxies";
import { AppComponentBase } from "@shared/common/app-component-base";
import { AddressService } from "./address.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Subject, takeUntil, tap } from "rxjs";
import { camelCase } from "lodash-es";

export interface AddressEditForm {
    streetAddress: FormControl<string>;
    city: FormControl<string>;
    state: FormControl<StateDto>;
    stateId: FormControl<number>;
    country: FormControl<CountryDto>;
    countryId: FormControl<number>;
    postalCode: FormControl<string>;
}

@Component({
    selector: "app-address-form",
    templateUrl: "./address.component.html"
})
export class AddressComponent extends AppComponentBase implements OnInit, OnDestroy {
    public static mapAddressModelToFormGroup(
        model: AddressViewModel,
        formGroup: FormGroup<AddressEditForm>,
        asReset = false,
        emitEvent = false
    ) {
        const assignments = {
            streetAddress: model?.streetAddress,
            city: model?.city,
            stateId: model?.stateId,
            countryId: model?.countryId,
            postalCode: model?.postalCode
        };
        if (!asReset) {
            formGroup.patchValue(assignments, { emitEvent });
        } else {
            formGroup.reset(assignments, { emitEvent });
        }
    }

    @Input() formGroup: FormGroup<AddressEditForm>;
    @Input() layout: "stacked" | "twoColumn" = "stacked";

    states: StateDto[];
    countries: CountryDto[];

    getRequiredLabel(labelKey: string): string {
        return (
            this.l(labelKey) + (this.formGroup.get(camelCase(labelKey)).hasValidator(Validators.required) ? " *" : "")
        );
    }

    private readonly unsubscribeSubject$ = new Subject<void>();

    constructor(
        injector: Injector,
        private _addressService: AddressService
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.populateStatesArray();

        this.formGroup.controls.stateId.valueChanges
            .pipe(
                takeUntil(this.unsubscribeSubject$),
                tap((s) => this.setStateById(s))
            )
            .subscribe();
    }

    override ngOnDestroy(): void {
        this.unsubscribeSubject$.next(void 0);
        this.unsubscribeSubject$.complete();
        super.ngOnDestroy();
    }

    populateStatesArray(): void {
        if (this.states && this.states.length) {
            return;
        }

        this._addressService.loadStates().subscribe((states) => {
            this.states = [...states];

            if (this.states.length && this.formGroup.controls.stateId.value) {
                this.setStateById(this.formGroup.controls.stateId.value);
            } else if (this.states.length) {
                let firstItem = this.states[0];
                this.formGroup.patchValue({
                    state: firstItem,
                    stateId: firstItem != null ? firstItem.id : null
                });
            }
        });
    }

    onStateSelection(selectedState: SelectItem<StateDto>) {
        console.log("stateSelection triggered");
        this.setStateById(selectedState.value != null ? selectedState.value.id : null);
    }

    onCountrySelection(selectedCountry: SelectItem<CountryDto>) {
        this.setCountryById(selectedCountry.value.id);
    }

    private setCountryById(countryId: number) {
        throw new Error("Not Implemented.");
        let cachedCountry = this.countries.find((s) => s.id === countryId);
        if (!cachedCountry) {
            return;
        }
        // TODO: update states dropdown
        this.formGroup.patchValue(
            {
                state: null,
                stateId: null,
                country: cachedCountry,
                countryId: cachedCountry.id
            },
            { emitEvent: false }
        );
    }

    private setStateById(stateId: number) {
        let cachedState = this.states.find((s) => s != null && s.id === stateId);
        this.formGroup.patchValue(
            {
                state: cachedState ? cachedState : null,
                stateId: cachedState != null ? cachedState.id : null
            },
            { emitEvent: false }
        );
    }
}
