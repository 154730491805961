import { Component, Input } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";

@Component({
    selector: "status-badge",
    templateUrl: "./status-badge.component.html"
})
export class StatusBadgeComponent extends AppComponentBase {
    @Input() key: any;

    badgeStatusClass(): string {
        switch (this.key) {
            case "PaidInFull":
            case "Active":
                return "badge-success";
            case "Sent":
                return "badge-primary";
            case "Draft":
                return "badge-dark";
            case "Inactive":
                return "badge-secondary";
            case "Viewed":
            case "PartiallyPaid":
            case "Invited":
            default:
                return "badge-warning";
        }
    }
}
