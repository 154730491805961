import { Pipe, PipeTransform } from "@angular/core";
import { AddressViewModel } from "@shared/service-proxies/service-proxies";

@Pipe({
    name: "addressLineTwo"
})
export class AddressLineTwoPipe implements PipeTransform {
    transform(value: AddressViewModel): string {
        let address = "";
        if (value?.city) {
            address += value.city;
            if (value.stateName) {
                address += ", ";
            }
        }
        if (value?.stateName) {
            address += value.stateName;
        }
        if (value?.postalCode) {
            if (address.length) {
                address += " ";
            }
            address += value.postalCode;
        }

        return address;
    }
}
