import { Component, OnInit, Renderer2 } from "@angular/core";
import { NgxSpinnerTextService } from "@app/shared/ngx-spinner-text.service";
import { AppConsts } from "@shared/AppConsts";

@Component({
    selector: "app-root",
    template: `
        <router-outlet></router-outlet>
        <ngx-spinner type="ball-clip-rotate" size="medium" color="#5ba7ea">
            <p *ngIf="ngxSpinnerText">{{ getSpinnerText() }}</p>
        </ngx-spinner>
    `
})
export class RootComponent implements OnInit {
    ngxSpinnerText: NgxSpinnerTextService;

    constructor(
        _ngxSpinnerText: NgxSpinnerTextService,
        private renderer: Renderer2
    ) {
        this.ngxSpinnerText = _ngxSpinnerText;
    }

    ngOnInit(): void {
        const bodyStyles = AppConsts.bodyStyles;
        if (bodyStyles) {
            this.setBodyStyles(bodyStyles);
        }
    }

    getSpinnerText(): string {
        return this.ngxSpinnerText.getText();
    }

    private setBodyStyles(bodyStyles: { [key: string]: string }) {
        const body = this.renderer.selectRootElement("body", true);

        Object.keys(bodyStyles).forEach((key) => {
            this.renderer.setStyle(body, key, bodyStyles[key]);
        });
    }
}
