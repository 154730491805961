import { Injector, Component, ViewEncapsulation } from "@angular/core";
import { AppConsts } from "@shared/AppConsts";
import { AppComponentBase } from "@shared/common/app-component-base";

@Component({
    templateUrl: "./voqaratheme1-brand.component.html",
    selector: "voqaratheme1-brand",
    encapsulation: ViewEncapsulation.None
})
export class VoqaraTheme1BrandComponent extends AppComponentBase {
    defaultLogo = AppConsts.appBaseUrl + "/assets/common/styles/themes/voqaratheme1/media/logos/default-dark.svg";
    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;

    constructor(injector: Injector) {
        super(injector);
    }
}
