import { Pipe, PipeTransform } from "@angular/core";
import { DiscountModel, DiscountType } from "@shared/service-proxies/service-proxies";

@Pipe({
    name: "discount"
})
export class DiscountPipe implements PipeTransform {
    transform(discount: DiscountModel | undefined): string {
        if (!discount) {
            return "";
        }

        if (discount.type === DiscountType.FlatAmount) {
            return `$${discount.amount}`;
        } else if (discount.type === DiscountType.Percent) {
            return `${discount.amount}%`;
        }
    }
}
