import { Pipe, PipeTransform } from "@angular/core";
import { DurationFormat, DurationUtils } from "@shared/helpers/duration-utils";

@Pipe({ name: "timerDisplayFormat" })
export class TimerDisplayFormatPipe implements PipeTransform {
    transform(value: number, format: DurationFormat | string): string | null {
        if (value === null || value === undefined || format === null || format === undefined) {
            return "";
        }

        if (format === "user") {
            //TODO: Select format based on user preference
            format = "fractional";
        }

        return DurationUtils.fromSeconds(value, format);
    }
}
