<ng-container
    *ngTemplateOutlet="
        layout === 'horizontal' ? horizontalLayout : layout === 'stacked' ? stackedLayout : verticalLayout
    "
></ng-container>

<ng-template #verticalLayout [formGroup]="parentForm">
    <div class="timer-field-details-wrapper">
        <div class="timer-fields-grid">
            <div>
                <p-floatLabel>
                    <ng-container *ngTemplateOutlet="projectField"></ng-container>
                    <label for="Project">{{ l("Project") }}</label>
                </p-floatLabel>
                <div *ngIf="projectControl.invalid" class="text-danger mt-1 ps-3">Project is required</div>
            </div>
            <div>
                <p-floatLabel>
                    <ng-container *ngTemplateOutlet="voqServiceField"></ng-container>
                    <label for="VoqService">{{ l("VoqService") }}</label>
                </p-floatLabel>
                <div *ngIf="voqServiceControl.invalid" class="text-danger mt-1 ps-3">Service is required</div>
            </div>
            <div>
                <p-floatLabel>
                    <ng-container *ngTemplateOutlet="taskField"></ng-container>
                    <label for="Issue">{{ l("Task") }}</label>
                </p-floatLabel>
            </div>
            <div
                class="d-flex align-items-center"
                *ngIf="
                    issueControl?.value?.issueKey &&
                        issueControl?.value?.jiraIssueId &&
                        issuesService.jiraIntegrationUrl | async
                "
            >
                <ng-container *ngTemplateOutlet="jiraIssueLink"></ng-container>
            </div>
            <div class="d-flex flex-nowrap align-items-center gap-2" *ngIf="selectedProject?.isBillable">
                <ng-container *ngTemplateOutlet="billableField"></ng-container>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #stackedLayout>
    <div class="timer-field-details-wrapper" [formGroup]="parentForm">
        <div class="timer-field-details flex-grow-1">
            <div class="row gy-8">
                <div class="col-12">
                    <ng-container *ngTemplateOutlet="projectField"></ng-container>
                    <div *ngIf="projectControl.invalid" class="text-danger mt-1 ps-3">Project is required</div>
                </div>
                <div class="col-12">
                    <ng-container *ngTemplateOutlet="voqServiceField"></ng-container>
                    <div *ngIf="voqServiceControl.invalid" class="text-danger mt-1 ps-3">Service is required</div>
                </div>
                <div class="col">
                    <ng-container *ngTemplateOutlet="taskField"></ng-container>
                </div>
                <div
                    class="col-auto d-flex align-items-center gap-2"
                    *ngIf="
                        issueControl?.value?.issueKey &&
                            issueControl?.value?.jiraIssueId &&
                            issuesService.jiraIntegrationUrl | async
                    "
                >
                    <ng-container *ngTemplateOutlet="jiraIssueLink"></ng-container>
                </div>
                <div class="col-12 d-flex flex-nowrap align-items-center" *ngIf="selectedProject?.isBillable">
                    <ng-container *ngTemplateOutlet="billableField"></ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #horizontalLayout>
    <div class="d-flex gap-5 align-items-center" [formGroup]="parentForm">
        <ng-container *ngTemplateOutlet="projectField"></ng-container>
        <ng-container *ngTemplateOutlet="voqServiceField"></ng-container>
        <ng-container *ngTemplateOutlet="taskField"></ng-container>
        <div class="d-flex flex-nowrap align-items-center gap-2" *ngIf="selectedProject?.isBillable">
            <ng-container *ngTemplateOutlet="billableField"></ng-container>
        </div>
        <div
            *ngIf="
                issueControl?.value?.issueKey && issueControl?.value?.jiraIssueId && issuesService.jiraIntegrationUrl
                    | async
            "
        >
            <ng-container *ngTemplateOutlet="jiraIssueLink"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #projectField>
    <label for="Project" *ngIf="layout === 'stacked'">{{ l("Project") }}*</label>
    <p-dropdown
        [formControl]="projectControl"
        [styleClass]="layout === 'stacked' ? '' : layout === 'horizontal' ? inputClass : 'outline-lg'"
        dropdownIcon="pi pi-chevron-down chevron-down"
        [panelStyleClass]="layout === 'stacked' ? '' : 'outline-panel'"
        id="Project"
        name="Project"
        [options]="projects$ | async"
        optionLabel="name"
        dataKey="id"
        [filter]="true"
        filterBy="filterField"
        placeholder="Project"
        [showClear]="true"
        [ngClass]="{ 'ng-invalid': saveFailed }"
    >
        <ng-template let-item pTemplate="selectedItem">
            <div class="d-flex gap-2 align-items-center">
                <span
                    *ngIf="item?.color"
                    class="project-indicator-color"
                    [ngStyle]="{ 'background-color': item?.color }"
                ></span>
                {{ item?.name }}
            </div>
        </ng-template>
        <ng-template let-item pTemplate="item">
            <div>{{ item?.name }}</div>
            <small class="text-secondary">{{ item?.clientName }}</small>
        </ng-template>
    </p-dropdown>
</ng-template>

<ng-template #voqServiceField>
    <label for="VoqService" *ngIf="layout === 'stacked'">{{ l("VoqService") }}*</label>
    <p-dropdown
        [formControl]="voqServiceControl"
        [styleClass]="layout === 'stacked' ? '' : layout === 'horizontal' ? inputClass : 'outline-lg'"
        dropdownIcon="pi pi-chevron-down chevron-down"
        [panelStyleClass]="layout === 'stacked' ? '' : 'outline-panel'"
        id="VoqService"
        name="VoqService"
        [options]="voqServices$ | async"
        optionLabel="name"
        datakey="id"
        placeholder="Service"
        [showClear]="true"
        [filter]="true"
        filterBy="name"
        [ngClass]="{ 'ng-invalid': saveFailed }"
    ></p-dropdown>
</ng-template>

<ng-template #taskField>
    <label for="Issue" *ngIf="layout === 'stacked'">{{ l("Task") }}</label>
    <p-dropdown
        id="Issue"
        name="Issue"
        [formControl]="issueControl"
        [styleClass]="layout === 'stacked' ? '' : layout === 'horizontal' ? inputClass : 'outline-lg'"
        dropdownIcon="pi pi-chevron-down chevron-down"
        [panelStyleClass]="layout === 'stacked' ? '' : 'outline-panel'"
        [options]="issues$ | async"
        dataKey="issueKey"
        (onFilter)="filterIssues$.next($event?.filter ?? undefined)"
        (onChange)="onIssueChange($event?.value)"
        (onShow)="updateIssues()"
        [showClear]="true"
        [filter]="true"
        [resetFilterOnHide]="true"
        filterBy="issueKey"
        [placeholder]="l('Task')"
        [ngClass]="{ 'ng-invalid': saveFailed }"
    >
        <ng-template let-issue pTemplate="selectedItem">
            {{ issue?.issueKey }}
        </ng-template>
        <ng-template let-issue pTemplate="item">
            <div class="text-wrap">{{ issue?.issueKey }}: {{ issue?.title }}</div>
        </ng-template>
    </p-dropdown>
</ng-template>

<ng-template #jiraIssueLink>
    <a [href]="(issuesService.jiraIntegrationUrl | async) + '/browse/' + issueControl?.value?.issueKey" target="_blank">
        <span [inlineSVG]="'/assets/metronic/themes/voqaratheme1/css/timer-share.svg'"></span>
    </a>
</ng-template>

<ng-template #billableField>
    <label for="isBillable" class="form-check-label billable-checkbox">Billable</label>
    <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" id="isBillable" [formControl]="billableControl" />
    </div>
</ng-template>
