import { CommonModule } from "@angular/common";
import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { AppComponentBase } from "@shared/common/app-component-base";
import {
    IssueSummaryModel,
    ProjectSummaryModel,
    TimeEntryServiceProxy,
    VoqServiceSummaryModel
} from "@shared/service-proxies/service-proxies";
import { IssuesService } from "@timer/services/issues.service";
import { ProjectsService } from "@timer/services/projects.service";
import { VoqServicesService } from "@timer/services/voq-services.service";
import { InlineSVGModule } from "ng-inline-svg-2";
import { CheckboxModule } from "primeng/checkbox";
import { DropdownModule } from "primeng/dropdown";
import { FloatLabelModule } from "primeng/floatlabel";
import {
    BehaviorSubject,
    Observable,
    Subject,
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    firstValueFrom,
    map,
    takeUntil
} from "rxjs";

export interface TimerFieldDetailsForm {
    project: FormControl<ProjectSummaryModel>;
    voqService: FormControl<VoqServiceSummaryModel>;
    billable: FormControl<boolean>;
    issue: FormControl<IssueSummaryModel>;
    assignee: FormControl<{ name: string; code: string }>;
    status: FormControl<{ name: string; code: string }>;
}

interface FilterableProjectViewModel extends ProjectSummaryModel {
    filterField: string;
}

@Component({
    standalone: true,
    selector: "app-timer-field-details",
    templateUrl: "./timer-field-details.component.html",
    styleUrls: ["./timer-field-details.component.scss"],
    imports: [CommonModule, ReactiveFormsModule, DropdownModule, CheckboxModule, InlineSVGModule, FloatLabelModule]
})
export class TimerFieldDetailsComponent extends AppComponentBase implements OnInit, OnChanges {
    @Input() parentForm!: FormGroup<TimerFieldDetailsForm>;
    @Input() selectedProject!: ProjectSummaryModel;
    // TODO: Input class and the layout could be expanded to a configuration object; if done, need to be mindful of change detection
    @Input() inputClass?: string;
    @Input() saveFailed = false;
    @Input() layout: "horizontal" | "stacked" | "vertical" = "vertical";

    projects$!: Observable<ProjectSummaryModel[]>;
    issues$!: Observable<IssueSummaryModel[]>;
    voqServices$!: Observable<VoqServiceSummaryModel[]>;

    get projectControl(): FormControl<ProjectSummaryModel> {
        return this.parentForm.controls.project;
    }
    get voqServiceControl(): FormControl<VoqServiceSummaryModel | null> {
        return this.parentForm.controls.voqService;
    }
    get billableControl(): FormControl<boolean> {
        return this.parentForm.controls.billable;
    }
    get issueControl(): FormControl<IssueSummaryModel> {
        return this.parentForm.controls.issue;
    }

    readonly filterIssues$ = new Subject<string | undefined>();
    private readonly selectedProject$ = new BehaviorSubject<ProjectSummaryModel | undefined>(undefined);
    private readonly unsubscribeSubject$ = new Subject<void>();

    constructor(
        injector: Injector,
        public formBuilder: FormBuilder,
        public timeEntryService: TimeEntryServiceProxy,
        public issuesService: IssuesService,
        private projectsService: ProjectsService,
        private voqServicesService: VoqServicesService
    ) {
        super(injector);
    }

    async ngOnInit(): Promise<void> {
        this.projectControl.valueChanges.pipe(takeUntil(this.unsubscribeSubject$)).subscribe((project) => {
            if (project) {
                this.voqServiceControl.enable();
            } else {
                this.voqServiceControl.markAsDirty();
                this.voqServiceControl.setValue(null);
                this.voqServiceControl.disable();
            }
        });

        this.projects$ = this.projectsService.getActivePlusSelected(this.selectedProject?.id).pipe(
            map((projects) =>
                projects.map(
                    (project) =>
                        ({
                            ...project,
                            filterField: `${project.clientName} ${project.name}`
                        }) as FilterableProjectViewModel
                )
            )
        );

        this.issues$ = this.issuesService.issues$.pipe(
            map((issues) => {
                const issuesWithSelected = [...issues];
                if (this.issueControl.value && !issues.find((i) => i.id === this.issueControl.value.id)) {
                    issuesWithSelected.unshift(this.issueControl.value);
                }

                return issuesWithSelected.sort((a, b) => b.issueKey?.localeCompare(a.issueKey ?? "") ?? 0);
            })
        );

        this.voqServices$ = combineLatest(
            [this.selectedProject$, this.voqServicesService.voqServices$],
            (project, voqServices) => {
                if (!project) {
                    return voqServices;
                }

                return voqServices.filter(
                    (vs) => project.voqServiceIds?.includes(vs.id) || vs.id === this.voqServiceControl.value?.id
                );
            }
        );

        this.filterIssues$
            .pipe(takeUntil(this.unsubscribeSubject$), distinctUntilChanged(), debounceTime(1000))
            .subscribe((filter) => this.updateIssues(filter));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["selectedProject"]) {
            this.selectedProject$.next(changes["selectedProject"].currentValue);
        }
    }

    async updateIssues(filter: string | undefined = undefined): Promise<void> {
        if (filter !== undefined && filter.length) {
            await firstValueFrom(this.issuesService.findIssues(filter));
        } else if (this.projectControl.value) {
            await firstValueFrom(this.issuesService.getIssuesByProject(this.projectControl.value.id));
        }
    }

    async onIssueChange(issue: IssueSummaryModel | undefined): Promise<void> {
        if (issue) {
            const project = await firstValueFrom(this.projectsService.getCachedProjectById(issue.projectId));
            this.parentForm.patchValue({ project });
        } else {
            this.parentForm.patchValue({ issue: undefined, assignee: undefined, status: undefined });
        }
    }
}
