import { Injector, Component, ViewEncapsulation, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { AppComponentBase } from "@shared/common/app-component-base";

@Component({
    templateUrl: "./header.component.html",
    selector: "voqaratheme1-header",
    encapsulation: ViewEncapsulation.None
})
export class Voqaratheme1HeaderComponent extends AppComponentBase implements OnInit {
    readonly voqServiceListPage: string = "/app/admin/services";
    readonly voqServiceEditPage: string = "/app/admin/services/";
    readonly invoiceListPage: string = "/app/main/invoices";
    readonly clientListPage: string = "/app/admin/clients";
    readonly clientEditPage: string = "/app/admin/clients/";
    readonly projectListPage: string = "/app/admin/projects";
    readonly projectEditPage: string = "/app/admin/projects/";
    readonly staffListPage: string = "/app/admin/staff-members";
    readonly staffEditPage: string = "/app/admin/staff-members/edit/";
    readonly invoiceEditPage: string = "/app/main/invoices/edit-invoice/";
    readonly paymentsPage: string = "/app/main/payments";
    readonly addPaymentPage: string = "/app/main/payments/add-payment";
    readonly issuesListPage: string = "/app/main/tasks";

    currentPage: string;
    isVoqServiceEditPage: boolean;
    isClientEditPage: boolean;
    isProjectEditPage: boolean;
    isInvoicesEditPage: boolean;
    isIssuesListPage: boolean;
    isStaffEditPage: boolean;
    constructor(injector: Injector, private router: Router, private activatedRoute: ActivatedRoute) {
        super(injector);

        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.setCurrentPage(event.url);
            }
        });
    }

    ngOnInit(): void {
        this.setCurrentPage(this.router.url);
    }

    setCurrentPage(url: string) {
        this.currentPage = url;
        this.isVoqServiceEditPage = url.startsWith(this.voqServiceEditPage);
        this.isClientEditPage = url.startsWith(this.clientEditPage);
        this.isProjectEditPage = url.startsWith(this.projectEditPage);
        this.isInvoicesEditPage = url.startsWith(this.invoiceEditPage);
        this.isIssuesListPage = url.startsWith(this.issuesListPage);
        this.isStaffEditPage = url.startsWith(this.staffEditPage);
    }

    createVoqService(): void {
        abp.event.trigger(VoqHeaderEventId.CreateVoqService);
    }

    createInvoice(): void {
        abp.event.trigger(VoqHeaderEventId.CreateInvoice);
    }

    createClient(): void {
        abp.event.trigger(VoqHeaderEventId.CreateClient);
    }

    createStaffMember(): void {
        abp.event.trigger(VoqHeaderEventId.CreateStaffMember);
    }

    createProject(): void {
        abp.event.trigger(VoqHeaderEventId.CreateProject);
    }

    createIssue(): void {
        abp.event.trigger(VoqHeaderEventId.CreateIssue);
    }
}

export class VoqHeaderEventId {
    public static readonly CreateVoqService = "app.header.createVoqService";
    public static readonly CreateInvoice = "app.header.createInvoice";
    public static readonly CreateClient = "app.header.createClient";
    public static readonly CreateStaffMember = "app.header.createStaffMember";
    public static readonly CreateProject = "app.header.createProject";
    public static readonly AddPayment = "app.header.addPayment";
    public static readonly CreateIssue = "app.header.createIssue";
}
