import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";
import { fromEvent, merge, BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class ConnectivityService {
    $isConnected = new BehaviorSubject<boolean>(true);

    constructor(messageService: MessageService) {
        merge(
            fromEvent(window, "offline").pipe(map(() => false)),
            fromEvent(window, "online").pipe(map(() => true))
        ).subscribe({
            next: (isOnline) => {
                this.$isConnected.next(isOnline);
                //TODO: This might only be desireable in the TimerPage
                if (isOnline) {
                    messageService.clear();
                } else {
                    messageService.add({
                        severity: "warn",
                        summary: "No Connection",
                        detail: "Cannot connect to the internet.",
                        sticky: true,
                        closable: false
                    });
                }
            }
        });
    }
}
