import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { DateTime } from "luxon";
import { ColumnFilter } from "primeng/table";

@Component({
    selector: "app-column-daterange-filter",
    template: `
        <p-columnFilter
            #columnFilter
            type="date"
            display="menu"
            [showMenu]="true"
            [showMatchModes]="false"
            [showOperator]="false"
            [showClearButton]="false"
            [showAddButton]="false"
            [showApplyButton]="false"
        >
            <ng-template pTemplate="filter" let-filter="filterCallback">
                <div class="d-flex gap-2">
                    <p-calendar
                        class="w-150px"
                        selectionMode="single"
                        [showTime]="false"
                        [showClear]="true"
                        [placeholder]="l('Start')"
                        [(ngModel)]="startDate"
                        (onClear)="startDate = undefined"
                    ></p-calendar>
                    <p-calendar
                        class="w-150px"
                        selectionMode="single"
                        [showTime]="false"
                        [showClear]="true"
                        [placeholder]="l('End')"
                        [(ngModel)]="endDate"
                        (onClear)="endDate = undefined"
                    ></p-calendar>
                </div>
                <ng-content></ng-content>
                <div class="d-flex py-2 justify-content-between" style="margin-bottom: -2rem;">
                    <p-button
                        styleClass="p-button-outlined"
                        [disabled]="!startDate && !endDate"
                        [label]="l('Clear')"
                        (onClick)="clear()"
                    ></p-button>
                    <p-button [label]="l('Apply')" (onClick)="apply()"></p-button>
                </div>
            </ng-template>
        </p-columnFilter>
    `
})
export class ColumnDaterangeFilterComponent extends AppComponentBase {
    @ViewChild("columnFilter") columnFilter!: ColumnFilter;

    @Input() range: DateTime[] = [];
    @Output() rangeChange = new EventEmitter<(DateTime | undefined)[] | undefined>();
    @Output() cleared = new EventEmitter();

    startDate?: Date;
    endDate?: Date;

    clear(): void {
        this.startDate = undefined;
        this.endDate = undefined;
        this.cleared.emit();
        this.finalize();
    }

    apply(): void {
        this.finalize(this.getDateTimeRange());
    }

    private getDateTimeRange(): (DateTime | undefined)[] | undefined {
        if (!this.startDate && !this.endDate) {
            return undefined;
        }

        const startDate = this.startDate ? DateTime.fromJSDate(this.startDate) : undefined;
        const endDate = this.endDate ? DateTime.fromJSDate(this.endDate) : undefined;

        return startDate?.isValid || endDate?.isValid
            ? [
                  startDate?.isValid ? startDate.startOf("day") : undefined,
                  endDate?.isValid ? endDate.endOf("day") : undefined
              ]
            : undefined;
    }

    private finalize(value?: (DateTime | undefined)[] | undefined): void {
        this.rangeChange.emit(value);
        this.columnFilter.applyFilter();
        this.columnFilter.hide();
    }
}
