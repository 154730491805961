import { Pipe, PipeTransform } from "@angular/core";
import { DateTime } from "luxon";

@Pipe({ name: "relativeDate" })
export class RelativeDatePipe implements PipeTransform {
    transform(date: DateTime): string {
        const todayStart = DateTime.now().startOf("day");
        if (date.hasSame(todayStart, "day")) {
            return "Today";
        } else if (date.hasSame(todayStart.minus({ days: 1 }), "day")) {
            return "Yesterday";
        } else {
            return date.toLocaleString({
                weekday: "long",
                month: "long",
                day: "numeric",
                year: "numeric"
            });
        }
    }
}
