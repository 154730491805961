import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "hexToRgba"
})
export class HexToRgbaPipe implements PipeTransform {
    transform(value: string, omitAlpha = false): string | null {
        if (!this.isValidHex(value)) {
            return null;
        }

        const hex = value.replace(/^#/, "");

        let r,
            g,
            b,
            a = 255;

        if (hex.length === 3) {
            // Handle shorthand hex (e.g. "03F")
            r = parseInt(hex[0] + hex[0], 16);
            g = parseInt(hex[1] + hex[1], 16);
            b = parseInt(hex[2] + hex[2], 16);
        } else if (hex.length === 6) {
            // Handle full hex (e.g. "0033FF")
            r = parseInt(hex.slice(0, 2), 16);
            g = parseInt(hex.slice(2, 4), 16);
            b = parseInt(hex.slice(4, 6), 16);
        } else if (hex.length === 8) {
            // Handle full hex with alpha (e.g. "0033FFAA")
            r = parseInt(hex.slice(0, 2), 16);
            g = parseInt(hex.slice(2, 4), 16);
            b = parseInt(hex.slice(4, 6), 16);
            a = parseInt(hex.slice(6, 8), 16);
        } else {
            return null; // Invalid length
        }

        const alpha = (a / 255).toFixed(2);
        return hex.length === 8 && !omitAlpha ? `${r}, ${g}, ${b}, ${alpha}` : `${r}, ${g}, ${b}`;
    }

    private isValidHex(hex: string): boolean {
        return /^#([0-9A-F]{3}|[0-9A-F]{6}|[0-9A-F]{8})$/i.test(hex);
    }
}
