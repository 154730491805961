import { Component, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ProjectVoqServiceModel, VoqServiceSummaryModel } from "@shared/service-proxies/service-proxies";
import { Table } from "primeng/table";

interface ProjectVoqServicesDetailForm {
    rowId: FormControl<number | null>;
    service: FormControl<VoqServiceSummaryModel | null | undefined>;
    isPrimary: FormControl<boolean | null>;
}

interface ProjectVoqServicesForm {
    voqServices: FormArray<FormGroup<ProjectVoqServicesDetailForm>>;
}

@Component({
    selector: "app-assigned-services",
    templateUrl: "./assigned-services.component.html"
})
export class AssignedServicesComponent extends AppComponentBase implements OnChanges {
    @ViewChild("voqServicesTable") voqServicesTable!: Table;

    @Input() availableVoqServices: VoqServiceSummaryModel[] = [];
    @Input() assignedVoqServices: ProjectVoqServiceModel[] = [];
    @Output() assignedVoqServicesChange = new EventEmitter<ProjectVoqServiceModel[]>();
    @Input() showSetPrimary = false;
    @Input() isDisabled = false;

    isUpdatingServices = false;
    voqServicesForm: FormGroup<ProjectVoqServicesForm>;

    get voqServicesControl(): FormArray<FormGroup<ProjectVoqServicesDetailForm>> {
        return this.voqServicesForm.controls.voqServices;
    }

    get assignServicesButtonIsDisabled(): boolean {
        return (
            this.voqServicesControl.length >= this.availableVoqServices.length ||
            this.isUpdatingServices ||
            this.isDisabled
        );
    }

    constructor(
        injector: Injector,
        private formBuilder: FormBuilder
    ) {
        super(injector);
        this.voqServicesForm = this.formBuilder.group<ProjectVoqServicesForm>({
            voqServices: this.formBuilder.array<FormGroup<ProjectVoqServicesDetailForm>>(
                [],
                [Validators.required, Validators.minLength(1)]
            )
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.assignedVoqServices) {
            this.prepVoqServicesTable();
        }
    }

    getFilteredVoqServices(serviceId: number): VoqServiceSummaryModel[] {
        const currentIds = this.voqServicesControl.controls.map((c) => c.value.service?.id).filter((c) => c);
        return this.availableVoqServices.filter((avs) => !currentIds.includes(avs.id) || avs.id === serviceId);
    }

    prepVoqServicesTable(): void {
        this.voqServicesControl.clear();
        const hasPrimary = this.assignedVoqServices.some((x) => x.isPrimary);
        if (!hasPrimary && this.showSetPrimary && this.assignedVoqServices.length) {
            this.assignedVoqServices[0].isPrimary = true;
        }

        this.assignedVoqServices.forEach((x: ProjectVoqServiceModel) => {
            this.voqServicesControl.push(
                this.formBuilder.group<ProjectVoqServicesDetailForm>({
                    rowId: new FormControl<number>(x.id),
                    service: new FormControl<VoqServiceSummaryModel | null | undefined>(
                        this.availableVoqServices.find((avs) => avs.id === x.serviceId)
                    ),
                    isPrimary: new FormControl<boolean>(x.isPrimary)
                })
            );
        });
    }

    prepNewServiceRow(): void {
        this.isUpdatingServices = true;

        const addRowGroup = this.formBuilder.group<ProjectVoqServicesDetailForm>({
            rowId: new FormControl(-1),
            service: new FormControl<VoqServiceSummaryModel | null>(null, Validators.required),
            isPrimary: new FormControl({ value: false, disabled: true })
        });

        this.voqServicesControl.push(addRowGroup);
        this.voqServicesTable.initRowEdit(addRowGroup);
    }

    onPrimaryServiceChanged(rowData: FormGroup<ProjectVoqServicesDetailForm>): void {
        this.assignedVoqServices = this.voqServicesControl.value.map((vsc) =>
            ProjectVoqServiceModel.fromJS({
                id: vsc.service?.id ?? undefined,
                serviceId: vsc.service?.id,
                serviceName: vsc.service?.name,
                isPrimary: vsc.service?.id === rowData.controls.service.value?.id
            })
        );

        this.assignedVoqServicesChange.emit(this.assignedVoqServices);
        this.isUpdatingServices = false;
    }

    async addVoqService(rowData: FormGroup<ProjectVoqServicesDetailForm>): Promise<void> {
        if (rowData.invalid) {
            rowData.controls.service.markAsDirty();
            return;
        }
        this.assignedVoqServices = [
            ...this.assignedVoqServices,
            ProjectVoqServiceModel.fromJS({
                serviceId: rowData.controls.service.value?.id,
                serviceName: rowData.controls.service.value?.name,
                isPrimary: false
            })
        ];
        this.prepVoqServicesTable();
        this.assignedVoqServicesChange.emit(this.assignedVoqServices);
        this.isUpdatingServices = false;
    }

    async deleteVoqService(rowData: FormGroup<ProjectVoqServicesDetailForm>): Promise<void> {
        this.assignedVoqServices = this.assignedVoqServices.filter(
            (avs) => avs.serviceId !== rowData.controls.service.value?.id
        );
        this.prepVoqServicesTable();
        this.assignedVoqServicesChange.emit(this.assignedVoqServices);
    }

    async cancelAddEditService(rowData: FormGroup<ProjectVoqServicesDetailForm>): Promise<void> {
        this.voqServicesControl.removeAt(this.voqServicesControl.controls.indexOf(rowData));
        this.isUpdatingServices = false;
    }
}
