import { CurrencyPipe } from "@angular/common";
import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";
import { CompensationDto } from "@shared/service-proxies/service-proxies";

@Pipe({ name: "compensation" })
export class CompensationPipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) private _locale: string) {}

    transform(compensation: CompensationDto): string {
        if (compensation == null || compensation === undefined) {
            return "-";
        }
        const amount = new CurrencyPipe(this._locale).transform(compensation.amount, "USD", "symbol", "1.2");

        if (compensation.payRateType?.name?.toLowerCase() === "hourly") {
            return `${amount} /hr`;
        } else {
            return `${amount} /yr`;
        }
    }
}
