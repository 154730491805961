import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HttpClientJsonpModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { AppRoutingModule } from "@app/app-routing.module";
import { AppBsModalModule } from "@shared/common/appBsModal/app-bs-modal.module";
import { ServiceProxyModule } from "@shared/service-proxies/service-proxy.module";
import { UtilsModule } from "@shared/utils/utils.module";
import { IMaskModule } from "angular-imask";
import { FileUploadModule } from "ng2-file-upload";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TabsModule } from "ngx-bootstrap/tabs";
import { ImageCropperComponent } from "ngx-image-cropper";
import { NgScrollbarModule } from "ngx-scrollbar";
import { NgxSpinnerModule } from "ngx-spinner";
import { AutoCompleteModule } from "primeng/autocomplete";
import { PaginatorModule } from "primeng/paginator";
import { ProgressBarModule } from "primeng/progressbar";
import { TableModule } from "primeng/table";
import { AppCommonModule } from "./common/app-common.module";
import { ThemesLayoutBaseComponent } from "./layout/themes/themes-layout-base.component";
import { StatusBadgeModule } from "./common/status-badge/status-badge.module";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { CarouselModule } from "primeng/carousel";
import { ConfirmPopupModule } from "primeng/confirmpopup";

const imports = [
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ModalModule,
    TabsModule,
    BsDropdownModule,
    PopoverModule,
    BsDatepickerModule,
    AppCommonModule,
    FileUploadModule,
    AppRoutingModule,
    UtilsModule,
    ServiceProxyModule,
    TableModule,
    PaginatorModule,
    ProgressBarModule,
    NgScrollbarModule,
    IMaskModule,
    ImageCropperComponent,
    AutoCompleteModule,
    NgxSpinnerModule,
    AppBsModalModule,
    StatusBadgeModule,
    ConfirmDialogModule,
    CarouselModule,
    ConfirmPopupModule
];

@NgModule({
    imports: [...imports, FormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" })],
    exports: [...imports, FormsModule],
    declarations: [ThemesLayoutBaseComponent]
})
export class AppSharedModule {}
