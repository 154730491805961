import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ColumnFilter } from "primeng/table";

export interface MinMaxNumber {
    min?: number;
    max?: number;
}

interface MinMaxForm {
    min?: FormControl<number | null>;
    max?: FormControl<number | null>;
}

@Component({
    selector: "app-column-amountrange-filter",
    template: `
        <p-columnFilter
            #columnFilter
            type="numeric"
            display="menu"
            [showMatchModes]="false"
            [showOperator]="false"
            [showAddButton]="false"
            [showApplyButton]="false"
            [showClearButton]="false"
        >
            <ng-template pTemplate="filter" let-filter="filterCallback">
                <div class="d-flex gap-2" [formGroup]="filterForm">
                    <p-inputNumber
                        class="w-100px"
                        formControlName="min"
                        mode="decimal"
                        locale="en-US"
                        [minFractionDigits]="2"
                        [placeholder]="l('MinValue')"
                        [ngClass]="{ 'ng-invalid': filterForm.errors?.['minMax'] }"
                        (onKeyDown)="keypress($event)"
                    ></p-inputNumber>
                    <p-inputNumber
                        class="w-100px"
                        formControlName="max"
                        mode="decimal"
                        locale="en-US"
                        [minFractionDigits]="2"
                        [placeholder]="l('MaxValue')"
                        [ngClass]="{ 'ng-invalid': filterForm.errors?.['minMax'] }"
                        (onKeyDown)="keypress($event)"
                    ></p-inputNumber>
                </div>
                <ng-content></ng-content>
                <div class="d-flex py-2 justify-content-between" style="margin-bottom: -2rem;">
                    <p-button
                        styleClass="p-button-outlined"
                        [disabled]="filterForm.pristine"
                        [label]="l('Clear')"
                        (onClick)="clear()"
                    ></p-button>
                    <p-button [label]="l('Apply')" (onClick)="apply()"></p-button>
                </div>
            </ng-template>
        </p-columnFilter>
    `
})
export class ColumnAmountrangeFilterComponent extends AppComponentBase implements OnInit {
    @ViewChild("columnFilter") columnFilter!: ColumnFilter;
    @Input() range: MinMaxNumber[] = [];
    @Output() rangeChange = new EventEmitter<MinMaxNumber>();
    @Output() cleared = new EventEmitter();

    filterValue?: MinMaxNumber;

    filterForm: FormGroup<MinMaxForm>;

    constructor(
        injector: Injector,
        private formBuilder: FormBuilder
    ) {
        super(injector);
        this.filterForm = this.formBuilder.group<MinMaxForm>(
            {
                min: new FormControl<number | null>(null),
                max: new FormControl<number | null>(null)
            },
            {
                validators: [Validators.required, this.minMaxValidator]
            }
        );
    }

    keypress(event: KeyboardEvent): void {
        if (event.key === "Enter") {
            this.apply();
        }
    }

    clear(): void {
        this.filterForm.reset();
        this.cleared.emit();
        this.apply();
    }

    apply(): void {
        this.filterForm.updateValueAndValidity();
        if (this.filterForm.valid) {
            this.rangeChange.emit(this.filterValue);
            this.columnFilter.applyFilter();
        }
        this.columnFilter.hide();
    }

    ngOnInit(): void {
        this.filterForm.valueChanges.subscribe(async (value: MinMaxNumber) => {
            if (this.filterForm.valid) {
                this.filterValue = !value.min && !value.max ? undefined : value;
            }
        });
    }

    minMaxValidator(group: FormGroup<MinMaxForm>): { minMax: boolean } | null {
        const min = group.get("min")?.value;
        const max = group.get("max")?.value;

        return !min || !max || min <= max ? null : { minMax: true };
    }
}
