import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class GoogleTagManagerService {
    private window: Window & typeof globalThis & { dataLayer: Array<unknown> };

    constructor(@Inject(DOCUMENT) document: Document) {
        this.window = document.defaultView as Window & typeof globalThis & { dataLayer: Array<unknown> };
    }

    logPageView(url): void {
        const obj = {
            event: "page-view",
            pageName: url
        };
        this.window.dataLayer.push(obj);
    }
}
