import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { NameValueDtoOfInt32 } from "@shared/service-proxies/service-proxies";

@Component({
    selector: "app-flatten-key-value-list",
    template: `
        <ng-container *ngFor="let item of items | slice: 0 : displayCount; let i = index">
            <ng-container
                *ngTemplateOutlet="
                    itemTemplate;
                    context: {
                        $implicit: item,
                        index: i,
                        suffix: (i < displayCount - 1 && totalItems >= displayCount) || extraItems ? ', ' : ''
                    }
                "
            ></ng-container>
        </ng-container>
        <ng-container *ngIf="totalItems === 0 && emptyTemplate; else emptyTemplate">
            <ng-container *ngTemplateOutlet="emptyTemplate"></ng-container>
            <ng-container #emptyNoTemplate>-</ng-container>
        </ng-container>
        <u [title]="extraItems" *ngIf="extraItems">+ {{ extraItemsCnt }}</u>
    `
})
export class FlattenKeyValueListComponent implements OnInit {
    @Input() items!: NameValueDtoOfInt32[];
    @Input() itemTemplate!: TemplateRef<unknown>;
    @Input() emptyTemplate?: TemplateRef<unknown>;
    @Input() displayCount!: number;

    extraItems?: string;
    extraItemsCnt = 0;
    totalItems = 0;

    ngOnInit(): void {
        if (this.items?.length === 0) {
            return;
        }
        this.totalItems = this.items?.length;

        this.extraItemsCnt = this.totalItems - this.displayCount;
        if (this.extraItemsCnt > 0) {
            this.extraItems = this.items
                .filter((item, index) => index >= this.displayCount)
                .map((item) => item.name)
                .join(", ");
        }
    }
}
