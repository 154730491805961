import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { VoqServiceSummaryModel, VoqServicesServiceProxy } from "@shared/service-proxies/service-proxies";
import { map, shareReplay } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class VoqServicesService {
    voqServices$: Observable<VoqServiceSummaryModel[]>;

    constructor(private voqServicesServiceProxy: VoqServicesServiceProxy) {
        this.voqServices$ = this.voqServicesServiceProxy.getSummaryList().pipe(shareReplay(1));
    }

    getVoqServiceById(id?: number): Observable<VoqServiceSummaryModel | undefined> {
        return this.voqServices$.pipe(map((voqServices) => voqServices.find((v) => v.id === id)));
    }
}
