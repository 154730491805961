<div class="row" [formGroup]="staffMembersForm">
    <h4 class="my-5">Users</h4>
    <p-table
        #staffMembersTable
        [rowHover]="true"
        [value]="projectStaffMembersControl.controls"
        formArrayName="staffMembers"
        dataKey="controls.rowId.value"
        editMode="row"
    >
        <ng-template pTemplate="header">
            <tr>
                <th>Name</th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="rowData" [formGroupName]="ri">
                <td>
                    <p-dropdown
                        *ngIf="editing; else staffMemberNameLabel"
                        formControlName="staffMember"
                        id="staffMember"
                        name="staffMember"
                        [options]="getFilteredStaffMembers(rowData.controls.staffMember.value?.value)"
                        optionLabel="name"
                        appendTo="body"
                        placeholder="Choose a user"
                    ></p-dropdown>
                    <ng-template #staffMemberNameLabel>
                        {{ rowData.controls.staffMember.value.name }}
                    </ng-template>
                </td>
                <td class="text-end">
                    <button
                        *ngIf="!isUpdatingStaffMembers"
                        type="button"
                        pButton
                        [title]="l('RemoveFromProject')"
                        [disabled]="isDisabled"
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-text"
                        (click)="deleteStaffMember(rowData)"
                    ></button>
                    <button
                        *ngIf="editing"
                        type="button"
                        pButton
                        icon="pi pi-check"
                        class="p-button-rounded p-button-text p-button-success"
                        (click)="addStaffMember(rowData)"
                    ></button>
                    <button
                        *ngIf="editing"
                        type="button"
                        pButton
                        pCancelEditableRow
                        icon="pi pi-times"
                        class="p-button-rounded p-button-text p-button-danger"
                        (click)="cancelAddEditStaffMember(rowData)"
                    ></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr>
                <td colspan="3" class="text-center">
                    <button
                        [disabled]="assignUserButtonIsDisabled"
                        class="btn btn-link text-decoration-underline"
                        (click)="prepNewStaffMemberRow()"
                    >
                        Assign User
                    </button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
