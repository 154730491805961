import { Component, EventEmitter, Injector, Input, Output, ViewChild } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { MultiSelect } from "primeng/multiselect";
import { ColumnFilter } from "primeng/table";

@Component({
    selector: "app-column-list-filter",
    template: `
        <p-columnFilter
            #columnFilter
            display="menu"
            [showMenu]="true"
            [showMatchModes]="false"
            [showOperator]="false"
            [showClearButton]="false"
            [showAddButton]="false"
            [showApplyButton]="false"
        >
            <ng-template pTemplate="filter" let-filter="filterCallback">
                <div style="margin-bottom: -1.5rem;">
                    <p-dropdown
                        *ngIf="!multiSelect; else multiSelectTemplate"
                        [options]="options"
                        [(ngModel)]="filterValue"
                        [showClear]="true"
                        [optionLabel]="optionLabel"
                        [optionValue]="optionValue"
                        [placeholder]="placeholder"
                        (onChange)="apply(filterValue)"
                        (onHide)="columnFilter.hide()"
                    ></p-dropdown>
                    <ng-template #multiSelectTemplate>
                        <p-multiSelect
                            #multiSelector
                            [options]="options"
                            [(ngModel)]="filterValue"
                            [showClear]="true"
                            [showHeader]="false"
                            [optionLabel]="optionLabel"
                            [optionValue]="optionValue"
                            [placeholder]="placeholder"
                            (onHide)="columnFilter.hide()"
                            (onClear)="apply()"
                            [maxSelectedLabels]="2"
                            [selectedItemsLabel]="l('NItemsSelected')"
                        ></p-multiSelect>
                        <ng-content></ng-content>
                        <div class="d-flex py-2 justify-content-between" style="margin-bottom: -2rem;">
                            <p-button
                                styleClass="p-button-outlined"
                                [disabled]="!filterValue"
                                [label]="l('Clear')"
                                (onClick)="clear()"
                            ></p-button>
                            <p-button [label]="l('Apply')" (onClick)="apply(multiSelector.value)"></p-button>
                        </div>
                    </ng-template>
                </div>
            </ng-template>
        </p-columnFilter>
    `,
    styles: [
        `
            p-multiselect {
                display: inline-block;
                width: 250px;
            }
        `
    ]
})
export class ColumnListFilterComponent<T = unknown> extends AppComponentBase {
    @ViewChild("columnFilter") columnFilter!: ColumnFilter;
    @ViewChild("multiSelector") multiSelector!: MultiSelect;

    @Input() options: T[] = [];
    @Input() multiSelect!: boolean;
    @Input() placeholder?: string;
    @Input() optionLabel = "name";
    @Input() optionValue = "value";

    @Input() filterValue?: T = undefined;
    @Output() filterValueChange = new EventEmitter<unknown>();
    @Output() cleared = new EventEmitter();

    constructor(injector: Injector) {
        super(injector);
    }

    clear(): void {
        this.cleared.emit();
        this.apply();
    }

    apply(filterValue?: unknown): void {
        this.filterValueChange.emit(filterValue);
        this.columnFilter.applyFilter();
        this.columnFilter.hide();
    }
}
